import ApiService from '@/common/api.service';
import {
    CREATE_INCIDENT,
    CREATE_USER,
    DELETE_INCIDENT,
    FETCH_USERS,
    UPDATE_INCIDENT,
    FETCH_INCIDENT_REFERENCES,
    DELETE_USER,
    SEND_NOTIFICATION,
    FETCH_SEARCH_USER,
    FETCH_USER_SETTINGS,
    UPDATE_PASSWORD,
    UPDATE_VERIFICATIONS_COUNT,
    UPDATE_DISABLE_VERIFICATIONS,
    UPDATE_SETTINGS, FETCH_VERIFICATION_STATISTICS, FETCH_UPLOADED_XLSX, UPLOAD_XLSX, UPDATE_COMMERCIAL_PRUDENCE_LIMIT,
} from './actions.type';
import {
    SET_ERROR,
    FETCH_START,
    SET_INCIDENT,
    FETCH_END,
    SET_USERS,
    SET_INCIDENT_REFERENCES,
    SET_SEARCH_USERS,
    SET_USER_SETTINGS,
    SET_VERIFICATION_STATISTICS,
    SET_UPLOADED_XLSX,
    SET_UPLOADED_ERROR,
} from './mutations.type';


const state = {
    errors: null,
    users: [],
    userSettings: {},
    usersCount: 0,
    nextPage: null,
    previousPage: null,
    user: null,
    references: {},
    searchUsers: [],
    verificationStatistics: [],
    atiVerificationStatistics: [],
    uploadedXlsx: [],
    uploadError: false,
};

interface State {
    errors: null | string;
    users: object[];
    usersCount: number;
    nextPage: string;
    previousPage: string;
    user: object | null;
    references: any;
    searchUsers: object[];
    userSettings: any;
    verificationStatistics: object[];
    atiVerificationStatistics: object[];
    uploadedXlsx: object[];
    uploadError: boolean;
}

const getters = {
    usersCount(state: State) {
        return state.usersCount;
    },
    users(state: State) {
        return state.users;
    },
    references(state: State) {
        return state.references;
    },
    searchUsers(state: State) {
        return state.searchUsers;
    },
    userSettings(state: State) {
        return state.userSettings;
    },
    verificationStatistics(state: State) {
        return state.verificationStatistics;
    },
    atiVerificationStatistics(state: State) {
        return state.atiVerificationStatistics;
    },
    uploadedXlsx(state: State) {
        return state.uploadedXlsx;
    },
    uploadError(state: State) {
        return state.uploadError;
    },
};

const actions = {
    async [FETCH_USERS](context: any, params: any = {}) {
        context.commit(FETCH_START);
        try {
            const preparedData = {
                ...params,
            };
            const resp: any = await ApiService.query('/impact/reg_admin', preparedData);
            context.commit(SET_USERS, resp.data);
        } catch (e) {
            context.commit(SET_ERROR, e);
        }
        context.commit(FETCH_END);
    },
    async [FETCH_USER_SETTINGS](context: any, slug: string) {
        context.commit(FETCH_START);
        try {
            const resp: any = await ApiService.get('/impact/reg_admin', slug);
            context.commit(SET_USER_SETTINGS, resp.data);
        } catch (e) {
            context.commit(SET_ERROR, e);
        }
        context.commit(FETCH_END);
    },
    async [UPDATE_PASSWORD](context: any, data: any) {
        context.commit(FETCH_START);
        try {
            await ApiService.post(`/impact/reg_admin/${data.id}/change_password`, data);
        } catch (e) {
            context.commit(SET_ERROR, e);
        }
        context.commit(FETCH_END);
    },
    async [UPDATE_SETTINGS](context: any, data: any) {
        context.commit(FETCH_START);
        try {
            await ApiService.post(`/impact/reg_admin/${data.id}/update_settings`, data);
        } catch (e) {
            context.commit(SET_ERROR, e);
        }
        context.commit(FETCH_END);
    },
    async [UPDATE_VERIFICATIONS_COUNT](context: any, data: any) {
        context.commit(FETCH_START);
        try {
            await ApiService.post(`/impact/reg_admin/${data.id}/set_verification_count`, data);
        } catch (e) {
            context.commit(SET_ERROR, e);
        }
        context.commit(FETCH_END);
    },
    async [UPDATE_DISABLE_VERIFICATIONS](context: any, data: any) {
        context.commit(FETCH_START);
        try {
            await ApiService.post(`/impact/reg_admin/${data.id}/set_disable_verifications`, data);
        } catch (e) {
            context.commit(SET_ERROR, e);
        }
        context.commit(FETCH_END);
    },
    async [UPDATE_COMMERCIAL_PRUDENCE_LIMIT](context: any, data: any) {
        context.commit(FETCH_START);
        try {
            await ApiService.post(`/impact/reg_admin/${data.id}/set_commercial_prudence_limit`, data);
        } catch (e) {
            context.commit(SET_ERROR, e);
        }
        context.commit(FETCH_END);
    },
    async [CREATE_USER](context: any, params: any = {}) {
        let created: boolean = false;
        context.commit(FETCH_START);
        try {
            const resp: any = await ApiService.post(`/impact/reg_admin/${params.id}/create_user`, params);
            created = true;
        } catch (e) {
            context.commit(SET_ERROR, e);
        }
        context.commit(FETCH_END);
        return created;
    },
    async [DELETE_USER](context: any, userId: number) {
        try {
            await ApiService.delete(`/impact/reg_admin/${userId}`);
        } catch (e) {
            context.commit(SET_ERROR, e);
        }
    },
    async [FETCH_INCIDENT_REFERENCES](context: any) {
        context.commit(FETCH_START);
        try {
            const resp: any = await ApiService.query(`/impact/incident_admin/get_references`);
            context.commit(SET_INCIDENT_REFERENCES, resp.data);
        } catch (e) {
            context.commit(SET_ERROR, e);
        }
        context.commit(FETCH_END);
    },
    async [CREATE_INCIDENT](context: any, data: any = {}) {
        context.commit(FETCH_START);
        try {
            const resp: any = await ApiService.post(`/impact/incident_admin`, data);
            context.commit(SET_INCIDENT, resp.data);
        } catch (e) {
            context.commit(SET_ERROR, e.response);
        }
        context.commit(FETCH_END);
    },
    async [UPDATE_INCIDENT](context: any, data: any = {}) {
        context.commit(FETCH_START);
        try {
            const resp: any = await ApiService.put(`/impact/incident_admin/${data.id}`, data);
        } catch (e) {
            context.commit(SET_ERROR, e.response);
        }
        context.commit(FETCH_END);
    },
    async [DELETE_INCIDENT](context: any, slug: string | number) {
        context.commit(FETCH_START);
        try {
            const resp: any = await ApiService.delete(`/impact/incident_admin/${slug}`);
        } catch (e) {
            context.commit(SET_ERROR, e.response);
        }
        context.commit(FETCH_END);
    },
    async [SEND_NOTIFICATION](context: any, params: any) {
        context.commit(FETCH_START);
        let preparedData = params;
        const tmp = new FormData();
        if (params.file) {
            tmp.append('file',  preparedData.file,  preparedData.file.name);
        } else {
            delete preparedData.file;
        }
        Object.keys(preparedData).filter( (field: any) => field !== 'file').map( (field: any) => {
            tmp.append(field, preparedData[field]);
        });
        preparedData = tmp;
        try {
            await ApiService.post('/impact/user_admin/send_notification', preparedData);
        } catch (e) {
            context.commit(SET_ERROR, e.response);
        }
        context.commit(FETCH_END);
    },
    async [FETCH_SEARCH_USER](context: any, query: string = '') {
        context.commit(FETCH_START);
        try {
            const resp: any = await ApiService.query('/impact/user_admin', {s: query});
            context.commit(SET_SEARCH_USERS, resp.data);
        } catch (e) {
            context.commit(SET_ERROR, e);
        }
        context.commit(FETCH_END);
    },
    async [FETCH_VERIFICATION_STATISTICS](context: any) {
        context.commit(FETCH_START);
        try {
            const resp: any = await ApiService.query(`/impact/verification_log/by_month`);
            context.commit(SET_VERIFICATION_STATISTICS, resp.data);
        } catch (e) {
            context.commit(SET_ERROR, e);
        }
        context.commit(FETCH_END);
    },
    async [FETCH_UPLOADED_XLSX](context: any) {
        context.commit(FETCH_START);
        try {
            const resp: any = await ApiService.query(`/impact/incidents_file`);
            context.commit(SET_UPLOADED_XLSX, resp.data);
        } catch (e) {
            context.commit(SET_ERROR, e);
        }
        context.commit(FETCH_END);
    },
    async [UPLOAD_XLSX](context: any, xlsx: any) {
        context.commit(FETCH_START);
        const data = new FormData();
        data.append('incidents_file',  xlsx,  xlsx.name);
        try {
            const resp: any = await ApiService.postFile(`/impact/incidents_file`, data);
        } catch (e) {
            context.commit(SET_UPLOADED_ERROR, e);
        }
        context.commit(FETCH_END);
    },
};

const mutations = {
    [SET_USERS](state: State, data: any) {
        state.users = data.results;
        state.usersCount = data.count;
        state.nextPage = data.next;
        state.previousPage = data.previous;
    },
    [SET_USER_SETTINGS](state: State, data: any) {
        state.userSettings = {
            ...data,
        };
        state.userSettings.firstName = state.userSettings.first_name;
        state.userSettings.lastName = state.userSettings.last_name;
        state.userSettings.verificationCount = state.userSettings.verification_count;
        state.userSettings.notificationLanguage = state.userSettings.notification_language;
        state.userSettings.registrationDate = state.userSettings.registration_date;
        state.userSettings.disableCheckRisk = state.userSettings.disable_check_risk;
        state.userSettings.disableCheckFSSP = state.userSettings.disable_check_fssp;
        state.userSettings.disableCheckOSAGO = state.userSettings.disable_check_osago;
        state.userSettings.disableCheckDriver = state.userSettings.disable_check_driver;
        state.userSettings.disableCheckPassportComplex = state.userSettings.disable_check_passport_complex;
        state.userSettings.disableCheckPhone = state.userSettings.disable_check_phone;
        state.userSettings.disableCheckFace = state.userSettings.disable_check_face;
        state.userSettings.disableCheckTrustPerson = state.userSettings.disable_check_trust_person;
        state.userSettings.disableCheckReputation = state.userSettings.disable_check_reputation;
        state.userSettings.disableCheckParticipant = state.userSettings.disable_check_participant;
        state.userSettings.requiredVettingFields = state.userSettings.required_vetting_fields;
        state.userSettings.foreignDocuments = state.userSettings.foreign_documents;
        state.userSettings.enableCommercialPrudence = state.userSettings.enable_commercial_prudence;
        state.userSettings.commercialPrudenceLimitEnabled = state.userSettings.commercial_prudence_limit_enabled;
        state.userSettings.commercialPrudenceLimit = state.userSettings.commercial_prudence_limit;
        delete state.userSettings.first_name;
        delete state.userSettings.last_name;
        delete state.userSettings.notification_language;
        delete state.userSettings.registration_date;
        delete state.userSettings.disable_check_risk;
        delete state.userSettings.disable_check_fssp;
        delete state.userSettings.disable_check_osago;
        delete state.userSettings.disable_check_driver;
        delete state.userSettings.disable_check_passport_complex;
        delete state.userSettings.disable_check_phone;
        delete state.userSettings.disable_check_face;
        delete state.userSettings.disable_check_trust_person;
        delete state.userSettings.disable_check_reputation;
        delete state.userSettings.disable_check_participant;
        delete state.userSettings.enable_commercial_prudence;
        delete state.userSettings.required_vetting_fields;
        delete state.userSettings.foreign_documents;
    },
    [SET_SEARCH_USERS](state: State, data: any) {
        state.searchUsers = data;
    },
    [SET_INCIDENT_REFERENCES](state: State, data: any) {
        const references: any = {};
        Object.keys(data).map( (field) => {
            references[field] = [];
            Object.keys(data[field]).map( (item: any) => {
                references[field].push({value: data[field][item].id, text: data[field][item].name_ru});
            });
            references[field].sort( (a: any, b: any) => {
                let comp = 0;
                if (a.text.toUpperCase() > b.text.toUpperCase()) {
                    comp = 1;
                } else if (a.text.toUpperCase() < b.text.toUpperCase()) {
                    comp = -1;
                }
                return comp;
            } );
        });
        state.references = references;
    },
    [SET_VERIFICATION_STATISTICS](state: State, data: any) {
        state.verificationStatistics = data.statistics;
        state.atiVerificationStatistics = data.ati_statistics;
    },
    [SET_UPLOADED_XLSX](state: State, data: any) {
        state.uploadedXlsx = data;
    },
    [SET_UPLOADED_ERROR](state: State, data: any) {
        state.uploadError = true;
    },
};

export default {
    state,
    actions,
    mutations,
    getters,
};
