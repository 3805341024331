import ApiService from '@/common/api.service';
import { FETCH_STATISTICS, FETCH_CARGO_CATEGORY_DETAILS} from './actions.type';
import {
    SET_ERROR,
    FETCH_START,
    FETCH_END,
    SET_STATISTICS,
    SET_INCIDENTS_SUMMARY,
    SET_INCIDENTS_STATS, SET_CARGO_CATEGORY_DETAILS,
    PREPARE_FILTER_REQUEST,
    CHANGE_CURRENT_DAMAGE_BAR_CHUNK,
    SET_CUSTOM_FILTER,
} from './mutations.type';
import { i18n } from '@/translations';
import { numberWithCommas } from '@/common/helpers';


const state = {
    sByAttackMethod: {labels: [], data: []},
    sByCargoCategory: {labels: [], data: []},
    sByMonth: [],
    sByMonthLastYear: {labels: [], data: []},
    sByWeekday: {labels: [], data: []},
    sByRegion: {labels: [], data: []},
    sByIncidentCategory: {labels: [], data: []},
    sByFederalDistrict: [],
    sByTypeOfScene: {labels: [], data: []},
    sByPrice: {labels: [], data: []},
    sByCity: {labels: [], data: []},
    incidentsCategoryStats: {attack_method: [], incidents_category: {robbery: [], fraud: []}},
    topThreeIncidents: [],
    topTenIncByRegion: [{name: '', value: '', percent: 0},
                        {name: '', value: '', percent: 0},
                        {name: '', value: '', percent: 0},
                        {name: '', value: '', percent: 0},
                        {name: '', value: '', percent: 0},
                        {name: '', value: '', percent: 0},
                        {name: '', value: '', percent: 0},
                        {name: '', value: '', percent: 0},
                        {name: '', value: '', percent: 0},
                        {name: '', value: '', percent: 0}],
    topTenIncByCity: [{name: '', value: '', percent: 0},
                      {name: '', value: '', percent: 0},
                      {name: '', value: '', percent: 0},
                      {name: '', value: '', percent: 0},
                      {name: '', value: '', percent: 0},
                      {name: '', value: '', percent: 0},
                      {name: '', value: '', percent: 0},
                      {name: '', value: '', percent: 0},
                      {name: '', value: '', percent: 0},
                      {name: '', value: '', percent: 0}],
    lastIncidents: [],
    damageChange: null,
    isDamageIncreased: null,
    countChange: null,
    isCountIncreased: null,
    cargoCategoryDetails: {cargo_category: {name_ru: '', name_en: ''}, damage: 0, count: 0, total_damage: 0,
        region: {name_ru: '', name_en: ''}, region_count: null, federal_district: {name_ru: '', name_en: ''},
        attack_method: {name_ru: '', name_en: ''}, attack_method_count: null,
        incident_category: {name_ru: '', name_en: ''}, incident_category_count: null,
        type_of_scene: {name_ru: '', name_en: ''}, type_of_scene_count: null,
    },
    sByDistrictByPrice: {},
    currentDamageBarChunk: 0,
};

interface State {
    sByAttackMethod: {labels: object[], data: number[]};
    sByCargoCategory: {labels: object[], data: number[]};
    sByMonth: [];
    sByMonthLastYear: {labels: object[], data: number[]};
    sByWeekday: {labels: any, data: number[]};
    sByRegion: {labels: object[], data: number[]};
    sByIncidentCategory: {labels: object[], data: number[]};
    sByFederalDistrict: Array<{ label: { en: string, ru: string }, value: number }>;
    sByTypeOfScene: {labels: object[], data: number[]};
    sByPrice: {labels: object[], data: number[]};
    sByCity: {labels: object[], data: number[]};
    incidentsCategoryStats: any;
    topThreeIncidents: any[];
    topTenIncByRegion: any[];
    topTenIncByCity: any[];
    lastIncidents: any[];
    damageChange: any;
    isDamageIncreased: any;
    countChange: any;
    isCountIncreased: any;
    cargoCategoryDetails: any;
    sByDistrictByPrice: any;
    currentDamageBarChunk: number;
}

const getters = {
    sByAttackMethod(state: State) {
        return state.sByAttackMethod;
    },
    sByCargoCategory(state: State) {
        return state.sByCargoCategory;
    },
    sByMonth(state: State) {
        return state.sByMonth;
    },
    sByMonthLastYear(state: State) {
        return state.sByMonthLastYear;
    },
    sByWeekday(state: State) {
        return state.sByWeekday;
    },
    sByRegion(state: State) {
        return state.sByRegion;
    },
    sByIncidentCategory(state: State) {
        return state.sByIncidentCategory;
    },
    sByFederalDistrict(state: State) {
        return state.sByFederalDistrict;
    },
    sByTypeOfScene(state: State) {
        return state.sByTypeOfScene;
    },
    sByPrice(state: State) {
        return state.sByPrice;
    },
    sByCity(state: State) {
        return state.sByCity;
    },
    incidentsCategoryStats(state: State) {
        return state.incidentsCategoryStats;
    },
    topThreeIncidents(state: State) {
        return state.topThreeIncidents;
    },
    topTenIncByRegion(state: State) {
        return state.topTenIncByRegion;
    },
    topTenIncByCity(state: State) {
        return state.topTenIncByCity;
    },
    lastIncidents(state: State) {
        return state.lastIncidents;
    },
    damageChange(state: State) {
        return state.damageChange;
    },
    isDamageIncreased(state: State) {
        return state.isDamageIncreased;
    },
    countChange(state: State) {
        return state.countChange;
    },
    isCountIncreased(state: State) {
        return state.isCountIncreased;
    },
    cargoCategoryDetails(state: State) {
        return state.cargoCategoryDetails;
    },
    sByDistrictByPrice(state: State) {
        return state.sByDistrictByPrice;
    },
    currentDamageBarChunk(state: State) {
        return state.currentDamageBarChunk;
    },
};

const actions = {
    async [FETCH_STATISTICS](context: any, params: any = {}) {
        context.commit(FETCH_START);
        context.commit(PREPARE_FILTER_REQUEST);
        try {
            const resp: any = await ApiService.query('impact/incident/get_statistics',
                                                     context.rootState.filter.filterRequest);
            context.commit(SET_STATISTICS, resp.data);
            context.commit(CHANGE_CURRENT_DAMAGE_BAR_CHUNK, 0);
            context.commit(SET_INCIDENTS_SUMMARY, resp.data.summary);
            context.commit(SET_CARGO_CATEGORY_DETAILS, resp.data.cargo_category_details);
            context.commit(SET_INCIDENTS_STATS, resp.data);
        } catch (e) {
            context.commit(SET_ERROR, e);
        }
        context.commit(FETCH_END);
    },
    async [FETCH_CARGO_CATEGORY_DETAILS](context: any, params: any = {}) {
        context.commit(FETCH_START);
        if (params) {
          context.commit(SET_CUSTOM_FILTER, params);
        }
        params = { ...params, ...context.rootState.filter.filterRequest};
        try {
            const resp: any = await ApiService.query('impact/incident/cargo_category_details', params);
            context.commit(SET_CARGO_CATEGORY_DETAILS, resp.data.cargo_category_details);
        } catch (e) {
            context.commit(SET_ERROR, e);
        }
        context.commit(FETCH_END);
    },
};

const mutations = {
    [SET_STATISTICS](state: State, data: any) {
        state.sByAttackMethod = formatDataForChart(data.statistics.by_attack_method, data.reference.attack_method);
        state.sByCargoCategory = formatDataForChart(data.statistics.by_cargo_category, data.reference.cargo_category);
        state.sByMonth = sByMonthFormat(data.statistics.by_month);
        if (data.statistics.by_month_last_year) {
            state.sByMonthLastYear = sByMonthFormat(data.statistics.by_month_last_year);
        } else {
            state.sByMonthLastYear = { labels: [], data: [] };
        }
        state.sByWeekday = sByWeekdayFormat(data.statistics.by_weekday);
        state.sByPrice = formatDataForChart(data.statistics.by_price, data.reference.price_range, true);
        state.sByRegion = formatArrayDataForChart(data.statistics.by_region, data.reference.region);
        state.sByCity = formatDataForChart(data.statistics.by_city, data.reference.city);
        state.sByFederalDistrict = formatDataForCustomChart(data.statistics.by_federal_district,
            data.reference.federal_district);
        state.sByIncidentCategory = formatDataForChart(data.statistics.by_incident_category,
            data.reference.incident_category);
        state.sByTypeOfScene = formatDataForChart(data.statistics.by_type_of_scene, data.reference.type_of_scene);
        state.topThreeIncidents = data.top_three_incidents.map((incident: any) => {
            const tmp = { ...incident };
            tmp.city = { en: tmp.city_en, ru: tmp.city_ru };
            tmp.incident_category =  tmp.incident_category ? {
                en: data.reference.incident_category[tmp.incident_category].name_en,
                ru: data.reference.incident_category[tmp.incident_category].name_ru,
            } : { en: '', ru: ''};
            tmp.region = {
                en: tmp.region ? data.reference.region[tmp.region].name_en : '',
                ru: tmp.region ? data.reference.region[tmp.region].name_ru : '',
            };
            tmp.federal_district = {
                en: tmp.federal_district ? data.reference.federal_district[tmp.federal_district].name_en : '',
                ru: tmp.federal_district ? data.reference.federal_district[tmp.federal_district].name_ru : '',
            };
            return tmp;
        });
        state.topTenIncByRegion = formatRegionStatistics(data.statistics.by_region, data.reference.region);
        state.topTenIncByCity = formatCityStatistics(data.statistics.by_city);
        state.lastIncidents = data.last_incidents.map((incident: any) => {
            const tmp = { ...incident };
            return tmp;
        });
        state.damageChange = data.damage_change !== null ? numberWithCommas(data.damage_change) : null;
        state.isDamageIncreased = data.is_damage_increased;
        state.countChange = data.count_change !== null ? data.count_change : null;
        state.isCountIncreased = data.is_count_increased;
        state.sByDistrictByPrice = formatDistrictByPrice(data.statistics.by_district_by_price,
          data.reference.federal_district);
    },
    [SET_INCIDENTS_STATS](state: State, data: any) {
      const addLabels = (poorData: any) => {
          return poorData.map((row: any) => {
              const tmp = [
                {
                    en: data.reference.region[row[0]].name_en,
                    ru: data.reference.region[row[0]].name_ru,
                },
                row[1][0],
                row[1][1],
              ];
              return tmp;
            });
        };
      state.incidentsCategoryStats.attack_method = addLabels(data.table_stat.attack_method);
      state.incidentsCategoryStats.incidents_category = {
        robbery: addLabels(data.table_stat.incidents_category.robbery),
        fraud: addLabels(data.table_stat.incidents_category.fraud),
      };
    },
    [SET_CARGO_CATEGORY_DETAILS](state: State, data: any) {
        state.cargoCategoryDetails = data;
    },
    [CHANGE_CURRENT_DAMAGE_BAR_CHUNK](state: State, data: number) {
        state.currentDamageBarChunk = data;
    },
};

const sByMonthFormat = (items: any) => {
    return items.map((item: any) => {
       return {
           label: {
               en: i18n.t(`months[${item[1]}]`, 'en'),
               ru: i18n.t(`months[${item[1]}]`, 'ru'),
           },
           value: { count: item[2], sum: item[3] },
       };
    });
};

const sByWeekdayFormat = (items: any) => {
    return {
        labels: Object.keys(items).map( (day: any) => {
            return {
                en: i18n.t(`days[${day}]`, 'en'),
                ru: i18n.t(`days[${day}]`, 'ru'),
            };
        }),
        data: Object.keys(items).map( (day: any) => {
            return items[day];
        }),
    };
};

const formatDataForChart = (items: any, reference: any, noSort: boolean = false) => {
    const sortedArray = Object.keys(items).map( (key: any) => {
      const label = parseInt(key, 10) === -1 ? {
        en: i18n.t('strings.noData', 'en'),
        ru: i18n.t('strings.noData', 'ru'),
      } : {
        en: reference[key].name_en,
        ru: reference[key].name_ru,
      };
      return [label, items[key]];
    });
    if (!noSort) {
        sortedArray.sort( (a, b) => b[1] - a[1]);
    }

    return {
        labels: sortedArray.map( (item) => item[0]),
        data: sortedArray.map( (item) => item[1]),
    };
};

const formatDataForCustomChart = (items: any, reference: any, noSort: boolean = false) => {
    const sortedArray = Object.keys(items).map( (key) => {
        return {label: {en: reference[key].name_en, ru: reference[key].name_ru}, value: items[key]};
    });
    if (!noSort) {
        sortedArray.sort( (a, b) => b.value - a.value);
    }

    return sortedArray;
};

const formatDataWithoutTranslateForChart = (items: any) => {
    const sortedArray = items.map( (item: any) => {
        return [{en: item[0], ru: item[0]}, item[1]];
    });
    return {
        labels: sortedArray.map( (item: any) => item[0]),
        data: sortedArray.map( (item: any) => item[1]),
    };
};

const formatArrayDataForChart = (items: any, reference: any) => {
    const sortedArray = items.map( (item: any) => {
        return [{en: reference[item[0]].name_en, ru: reference[item[0]].name_ru}, item[1]];
    });
    return {
        labels: sortedArray.map( (item: any) => item[0]),
        data: sortedArray.map( (item: any) => item[1]),
    };
};

const formatRegionStatistics = (items: any, reference: any) => {
    const result = items.map( (item: any) => {
        return {value: item[1],
                en: reference[item[0]].name_en,
                ru: reference[item[0]].name_ru.replace('область', 'об.')
                                              .replace('Республика', 'Респ.')
                                              .replace('край', 'кр.'),
                percent: 0};
    });
    if (result.length) {
        const maxValue = result[0].value;
        result.map((item: any) => {
            item.percent = item.value / maxValue * 100;
        });
        return result.slice(0, 10);
    } else {
        return [];
    }
};

const formatCityStatistics = (data: any) => {
    const result = Object.keys(data).sort( (a, b) => {
        return data[b] - data[a];
    }).map( (cityName) => {
        return {name: cityName,
                value: data[cityName],
                percent: 0};
    });
    const maxValue = result[0].value;
    result.map( (item: any) => {
        item.percent = item.value / maxValue * 100;
    });
    return result.slice(0, 10);
};

const formatDistrictByPrice = (data: any, reference: any) => {
    const preparedData: any = {};
    Object.keys(data).map((districtId: any) => {
        preparedData[districtId] = {
            name_en: reference[districtId].name_en,
            name_ru: reference[districtId].name_ru,
            values: { ...data[districtId] },
        };
    });
    return preparedData;
};

export default {
    state,
    actions,
    mutations,
    getters,
};
