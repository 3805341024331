import moment from 'moment';
import { i18n } from '@/translations';

export const convertDate = (rawDate: string | Date): string =>  {
    const tmp = new Date(rawDate);
    const month = String(tmp.getMonth()).length === 2 || tmp.getMonth() === 9 ?
        tmp.getMonth() + 1
        : '0' + String(tmp.getMonth() + 1);
    const date = String(tmp.getDate()).length === 2 ? tmp.getDate() : '0' + String(tmp.getDate());
    return `${date}.${month}.${tmp.getFullYear()}`;
};

export const convertDayMonth = (rawDate: string): string =>  {
    const tmp = new Date(rawDate);
    const month = String(tmp.getMonth()).length === 2 || tmp.getMonth() === 9 ?
        tmp.getMonth() + 1
        : '0' + String(tmp.getMonth() + 1);
    const date = String(tmp.getDate()).length === 2 ? tmp.getDate() : '0' + String(tmp.getDate());
    return `${date}.${month}`;
};

export const convertDateToBackend = (rawDate: string | number): string =>  {
    return rawDate ? moment(rawDate, 'DD.MM.YYYY').format('YYYY-MM-DD') : '';
};

export const convertDateToDots = (rawDate: string | number): string =>  {
    return rawDate ? moment(rawDate, 'YYYY-MM-DD').format('DD.MM.YYYY') : '';
};

export const convertTimeToBackend = (rawDate: string | number): string =>  {
    const tmp = new Date(rawDate);
    return `${String(tmp.getHours()).length === 1
        ? '0' + tmp.getHours() : tmp.getHours()}:${String(tmp.getMinutes()).length === 1
        ? '0' + tmp.getMinutes() : tmp.getMinutes()}`;
};

export const numberWithCommas = (num: number, locale: string = 'ru'): string => {
    const separator = locale === 'ru' ? ' ' : ',';
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);
};

export const sumShape = (sum: number) => {
    let newSum = '';
    let prefix = '';
    if (sum < 1000) {
        newSum = String(sum);
    } else if (sum < 1000000) {
        newSum = String((sum / 1000).toFixed(1));
        if (newSum.indexOf('.0') > -1) {
            newSum = newSum.substring(0, newSum.indexOf('.0'));
        }
        prefix = i18n.t('strings.thousands') as string;
    } else if (sum < 1000000000) {
        newSum = String((sum / 1000000).toFixed(1));
        if (newSum.indexOf('.0') > -1) {
            newSum = newSum.substring(0, newSum.indexOf('.0'));
        }
        prefix = i18n.t('strings.millions') as string;
    } else if (sum < 1000000000000) {
        newSum = String((sum / 1000000000).toFixed(1));
        if (newSum.indexOf('.0') > -1) {
            newSum = newSum.substring(0, newSum.indexOf('.0'));
        }
        prefix = i18n.t('strings.billions') as string;
    }
    return newSum.replace('.', ',') + ' ' + prefix;
};

export const propI18n = (prop: any, i18n: any) => {
    const locale = i18n.locale;
    let propVal = prop;
    if (propVal === null) {
        propVal = i18n.t('strings.undefined');
    } else if (prop.hasOwnProperty('name_ru')) {
        propVal = prop['name_' + locale];
    }
    return propVal;
};

export const formatFederalDistrict = (item: any, i18n: any) => {
  const formatted = { ...item };
  const prefix = i18n.locale === 'ru' ? 'ФО' : 'FD';
  const formattedItem = formatted.hasOwnProperty('name_ru') ? formatted[`name_${i18n.locale}`] : formatted[i18n.locale];
  const newLabel = formattedItem.indexOf(' ') > -1
    ? formattedItem.split(' ') : formattedItem.split('-');
  return `${newLabel.map((word: any) => word[0]).join('')}${prefix}`;
};

export const pwdGen = (): string => {
    let tmpPwd: string = Math.random().toString(36).substring(2, 10);
    let tmpArr: any = [];
    let countLetters = 0;
    let i = 0;
    let bigLetterFut = '';
    let letterNumToReplace = 0;
    if (tmpPwd.search(/[0-9]/) === -1 || tmpPwd.search(/[A-Z]|[a-z]/)) {
        return pwdGen();
    }
    if (tmpPwd.search(/[A-Z]/) === -1) {
        tmpArr = tmpPwd.match(/[a-z]/g);
        countLetters = Math.floor(Math.random() * tmpArr.length);
        if (countLetters === 0) {
            countLetters += 1;
        }
        for (i = 0; i < countLetters; i += 1) {
            letterNumToReplace = Math.floor(Math.random() * tmpArr.length);
            bigLetterFut = tmpArr[letterNumToReplace];
            tmpPwd = tmpPwd.replace(bigLetterFut, bigLetterFut.toUpperCase());
            tmpArr.splice(letterNumToReplace, 1);
        }
    }
    return tmpPwd;
};

export const createMapZoom = () => {
  const zoomControlContainer = document.createElement('div');
  zoomControlContainer.style.height = '64px';
  zoomControlContainer.style.width = '32px';
  zoomControlContainer.style.margin = '16px';
  zoomControlContainer.style.borderRadius = '3px';
  zoomControlContainer.style.border = '0.5px solid #D1D1D6';
  zoomControlContainer.style.backgroundColor = '#FFFFFF';
  zoomControlContainer.style.display = 'flex';
  zoomControlContainer.style.alignItems = 'center';
  zoomControlContainer.style.flexDirection = 'column';

  const zoomIn = document.createElement('div');
  zoomIn.style.width = '100%';
  zoomIn.className = 'zoom-in-btn';
  zoomIn.style.cursor = 'pointer';
  zoomIn.style.height = '50%';
  zoomIn.style.display = 'flex';
  zoomIn.style.alignItems = 'center';
  zoomIn.style.borderBottom = '1px solid #D1D1D6';
  const zoomInImg = document.createElement('img');
  zoomInImg.src = '/images/zoom-in-icon.svg';
  zoomInImg.width = 12;
  zoomInImg.height = 12;
  zoomInImg.style.margin = 'auto';

  zoomIn.appendChild(zoomInImg);
  zoomControlContainer.appendChild(zoomIn);

  const zoomOut = document.createElement('div');
  zoomOut.style.width = '100%';
  zoomOut.className = 'zoom-out-btn';
  zoomOut.style.cursor = 'pointer';
  zoomOut.style.height = '50%';
  zoomOut.style.display = 'flex';
  zoomOut.style.alignItems = 'center';
  const zoomOutImg = document.createElement('img');
  zoomOutImg.src = '/images/zoom-out-icon.svg';
  zoomOutImg.style.margin = 'auto';
  zoomOutImg.width = 12;
  zoomOut.appendChild(zoomOutImg);
  zoomControlContainer.appendChild(zoomOut);
  return zoomControlContainer;
};

export const capitalize = (value: string) => {
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const toBase64 = (file: File) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error: any) => reject(error);
});

export function getFileFromBase64(string64: string, fileName: string) {
  const imageContent = atob(string64);
  const buffer = new ArrayBuffer(imageContent.length);
  const view = new Uint8Array(buffer);

  for (let n = 0; n < imageContent.length; n++) {
    view[n] = imageContent.charCodeAt(n);
  }
  const type = 'image/png';
  const blob = new Blob([buffer], { type });
  return new File([blob], fileName, { lastModified: new Date().getTime(), type });
}
