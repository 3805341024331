import ApiService from '@/common/api.service';
import { FETCH_NOTIFICATIONS, FETCH_FILE } from '@/store/actions.type';
import { FETCH_END, FETCH_START, SET_ERROR, SET_NOTIFICATIONS } from '@/store/mutations.type';
import saveAs from 'file-saver';


const state = {
    errors: null,
    notifications: [],
    notificationsCount: 0,
    nextPage: null,
    previousPage: null,
};

interface State {
    errors: any;
    notifications: object[];
    notificationsCount: number;
    nextPage: string;
    previousPage: string;
}

const getters = {
    notifications(state: State) {
        return state.notifications;
    },
    notificationsCount(state: State) {
        return state.notificationsCount;
    },
};

const actions = {
    async [FETCH_NOTIFICATIONS](context: any, params: any = {}) {
        context.commit(FETCH_START);
        try {
            const resp: any = await ApiService.query('/impact/notification', params);
            context.commit(SET_NOTIFICATIONS, resp.data);
        } catch (e) {
            context.commit(SET_ERROR, e);
        }
        context.commit(FETCH_END);
    },
    async [FETCH_FILE](context: any, notificationId: string) {
        try {
            const resp: any = await ApiService.getFile(`/impact/notification/${notificationId}/get_file`);
            const contentDisposition = resp.headers['content-disposition'];
            let fileName = 'attach';
            if (contentDisposition) {
                const fileNameMatch = contentDisposition.match(/filename="(.+)"/) ||
                    contentDisposition.match(/filename\*=.*''(.+)/);
                if (fileNameMatch.length === 2) {
                    fileName = decodeURI(fileNameMatch[1]);
                }
            }
            saveAs(resp.data, fileName);
        } catch (e) {
            context.commit(SET_ERROR, e);
        }
    },
};

const mutations = {
    [SET_NOTIFICATIONS](state: State, data: any) {
        state.notifications = data.results;
        state.notificationsCount = data.count;
        state.nextPage = data.next;
        state.previousPage = data.previous;
    },
};

export default {
    state,
    actions,
    mutations,
    getters,
};


