import {SET_ERROR, FETCH_START, FETCH_END, EXPORT_START, EXPORT_END, SET_NOTIFY, CLEAR_NOTIFY} from './mutations.type';
import {FETCH_INFO_TEXT, SEND_FEEDBACK} from '@/store/actions.type';
import ApiService from '@/common/api.service';


const state = {
  errors: null,
  isLoading: false,
  loadingCoordinates: false,
  fileLoading: false,
  infoText: '',
  currentNotify: false,
};

interface State {
  errors: object | null;
  isLoading: boolean;
  loadingCoordinates: boolean;
  fileLoading: boolean;
  infoText: string;
  currentNotify: string | boolean;
}

const getters = {
  errors(state: State) {
    return state.errors;
  },
  isLoading(state: State) {
    return state.isLoading;
  },
  loadingCoordinates(state: State) {
    return state.loadingCoordinates;
  },
  fileLoading(state: State) {
    return state.fileLoading;
  },
  infoText(state: State) {
    return state.infoText;
  },
  currentNotify(state: State) {
    return state.currentNotify;
  },
};

const actions = {
    async [FETCH_INFO_TEXT](context: any, slug: any) {
        context.commit(FETCH_START);
        try {
            const resp: any = await ApiService.query(`/impact/info_page/${slug}`);
            context.commit(FETCH_END);
            return resp.data;
        } catch (e) {
            context.commit(SET_ERROR, e);
            return '';
        }
        context.commit(FETCH_END);
    },
    async [SEND_FEEDBACK](context: any, feedback: string) {
        try {
            const resp: any = await ApiService.post(`/impact/feedback`, { feedback });
            return resp.data;
        } catch (e) {
            context.commit(SET_ERROR, e);
            return '';
        }
    },
};

const mutations = {
  [SET_ERROR](state: State, error: object) {
    state.errors = error;
  },
  [FETCH_START](state: State, mod: string) {
    state.isLoading = true;
    state.errors = null;
    if (mod === 'coordinates') {
      state.loadingCoordinates = true;
    }
  },
  [FETCH_END](state: State, mod: string) {
    state.isLoading = false;
    if (mod === 'coordinates') {
      state.loadingCoordinates = false;
    }
  },
  [EXPORT_START](state: State) {
    state.fileLoading = true;
  },
  [EXPORT_END](state: State) {
    state.fileLoading = false;
  },
  [SET_NOTIFY](state: State, msg: string) {
    state.currentNotify = msg;
  },
  [CLEAR_NOTIFY](state: State) {
    state.currentNotify = false;
  },
};

export default {
    state,
    actions,
    mutations,
    getters,
};
