import '@babel/polyfill';
import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import BootstrapVue from 'bootstrap-vue';
import ApiService from '@/common/api.service';
import { i18n } from '@/translations';
import VueSlider from 'vue-slider-component';
import vSelect from 'vue-select';
import linkify from 'vue-linkify';
import VueYandexMetrika from 'vue-yandex-metrika';

import 'vue-select/dist/vue-select.css';
import 'vue-slider-component/theme/default.css';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import LoginLayout from '@/layouts/LoginLayout.vue';
import BasicLayout from '@/layouts/BasicLayout.vue';

Vue.component('default-layout', DefaultLayout);
Vue.component('basic-layout', BasicLayout);
Vue.component('login-layout', LoginLayout);

Vue.use(BootstrapVue);
Vue.component('v-select', vSelect);
Vue.directive('linkified', linkify);
Vue.directive('click-outside', {
  bind(el: any, binding: any, vnode: any) {
    // eslint-disable-next-line
    el.clickOutsideEvent = (event: any) => {
      // here I check that click was outside the el and his childrens
      if (!(el === event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unbind(el: any) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  },
});

Vue.use(VueYandexMetrika, {
    id: 71973913,
    router,
    env: process.env.NODE_ENV,
    clickmap: true,
    trackLinks: true,
    accurateTrackBounce: true,
    webvisor: true,
    // other options
});

Vue.component('VueSlider', VueSlider);


Vue.config.productionTip = false;

ApiService.init();

new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
}).$mount('#app');
