import ApiService from '@/common/api.service';
import JwtService from '@/common/jwt.service';
import {
  LOGIN,
  CHECK_AUTH,
  REFRESH_TOKEN,
  REGISTRATION,
  FETCH_USER_INFO, FETCH_OFFICE_FUNCTION, UPDATE_PASSWORD_SELF, SEND_PASSWORD_RECOVERY, RESET_PASSWORD,
  UPDATE_SETTINGS_SELF,
} from './actions.type';
import {
  SET_AUTH,
  PURGE_AUTH,
  SET_ERROR,
  FETCH_START,
  FETCH_END,
  SET_USER,
  SET_OFFICE_FUNCTION,
  REDUCE_VERIFICATION, SET_NOTIFY, ACCEPT_TERMS, REFUSE_TERMS,
  SET_REGISTRATION_FORM,
} from './mutations.type';


const state = {
  user: {
    firstName: '',
    username: '',
    id: null,
    isStaff: false,
    verificationCount: 0,
    lastName: '',
    company: '',
    notificationLanguage: '',
    subscribed: true,
    demo: true,
    disabledChecks: 0,
    enableCommercialPrudence: false,
    commercialPrudenceLimitEnabled: false,
    commercialPrudenceLimit: 0,
    requiredVettingFields: 0,
    foreignDocuments: false,
    canDoReports: 0,
  },
  isAuthenticated: !!JwtService.getToken(),
  officeFunctionOptions: [],
  termsAccept: false,
  regFormData: {
    first_name: '',
    last_name: '',
    phone: '',
    email: '',
    password: '',
    inn: '',
    field_of_activity: '',
    office_function: '',
    company: '',
  },
};

const initialUserState = { ...state.user };

interface State {
  user: {
    firstName: string,
    username: string,
    id: number | null,
    isStaff: boolean,
    verificationCount: number,
    lastName: string,
    company: string,
    notificationLanguage: string,
    subscribed: boolean,
    demo: boolean,
    disabledChecks: number,
    enableCommercialPrudence: boolean,
    commercialPrudenceLimitEnabled: boolean,
    commercialPrudenceLimit: number,
    requiredVettingFields: number,
    foreignDocuments: boolean,
    canDoReports: number,
  };
  isAuthenticated: boolean;
  officeFunctionOptions: object[];
  termsAccept: boolean;
  regFormData: {
    first_name: string,
    last_name: string,
    phone: string,
    email: string,
    password: string,
    inn: string,
    field_of_activity: number | string,
    office_function: number | string,
    company: string,
  };
}

const getters = {
  currentUser(state: State) {
    return state.user;
  },
  isAuthenticated(state: State) {
    return state.isAuthenticated;
  },
  officeFunctionOptions(state: State) {
    return state.officeFunctionOptions;
  },
  termsAccept(state: State) {
    return state.termsAccept;
  },
  regFormData(state: State) {
    return state.regFormData;
  },
};

const actions = {
  async [LOGIN](context: any, data: object) {
    try {
      const resp: any = await ApiService.post('/auth/token/', data);
      context.commit(SET_AUTH, resp.data);
      return 0;
    } catch (e) {
      context.commit(SET_ERROR, e);
      return 1;
    }
  },
  async [REFRESH_TOKEN](context: any, token: object) {
    try {
      const resp: any = await ApiService.post('/auth/token/refresh/', { refresh: token });
      context.commit(SET_AUTH, resp.data);
      return 0;
    } catch (e) {
      context.commit(SET_ERROR, e);
      return 1;
    }
  },
  async [FETCH_USER_INFO](context: any) {
    if (context.state.user.id) {
      return;
    }
    context.commit(FETCH_START);
    try {
      const resp: any = await ApiService.query('/impact/user/current');
      context.commit(SET_USER, resp.data);
    } catch (e) {
      context.commit(SET_ERROR, e);
    }
    context.commit(FETCH_END);
  },
  [CHECK_AUTH](context: any) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      // ApiService.get('user')
      //     .then(({ data }) => {
      //         context.commit(SET_AUTH, data.user);
      //     })
      //     .catch(({ response }) => {
      //         context.commit(SET_ERROR, response.data.errors);
      //     });
    } else {
      context.commit(PURGE_AUTH);
    }
  },
  async [REGISTRATION](context: any, data: object) {
    context.commit(FETCH_START);
    try {
      const resp: any = await ApiService.post('/impact/registration', data);
      context.commit(FETCH_END);
      return true;
    } catch (e) {
      context.commit(SET_ERROR, e);
      context.commit(FETCH_END);
      return false;
    }
  },
  async [FETCH_OFFICE_FUNCTION](context: any) {
    context.commit(FETCH_START);
    try {
      const resp: any = await ApiService.query('/impact/office_function');
      context.commit(SET_OFFICE_FUNCTION, resp.data);
    } catch (e) {
      context.commit(SET_ERROR, e);
    }
    context.commit(FETCH_END);
  },
  async [UPDATE_PASSWORD_SELF](context: any, data: any) {
    context.commit(FETCH_START);
    try {
      await ApiService.post(`/impact/user/change_password`, data);
    } catch (e) {
      context.commit(SET_ERROR, e);
    }
    context.commit(FETCH_END);
  },
  async [UPDATE_SETTINGS_SELF](context: any, data: any) {
    context.commit(FETCH_START);
    try {
      await ApiService.post(`/impact/user/update_settings`, data);
    } catch (e) {
      context.commit(SET_ERROR, e);
    }
    context.commit(FETCH_END);
  },
  async [SEND_PASSWORD_RECOVERY](context: any, email: any) {
    context.commit(FETCH_START);
    try {
      await ApiService.post(`/impact/registration/forgot_password`, { email });
      context.commit(SET_NOTIFY, 'recoveryMsg');
    } catch (e) {
      context.commit(SET_ERROR, e);
    }
    context.commit(FETCH_END);
  },
  async [RESET_PASSWORD](context: any, params: any) {
    context.commit(FETCH_START);
    try {
      await ApiService.post(`/impact/registration/reset_password`, params);
    } catch (e) {
      context.commit(SET_ERROR, e.response);
    }
    context.commit(FETCH_END);
  },
};

const mutations = {
  [SET_AUTH](state: State, data: any) {
    state.isAuthenticated = true;
    JwtService.saveToken(data);
  },
  [PURGE_AUTH](sState: State) {
    sState.isAuthenticated = false;
    sState.user = { ...initialUserState };
    JwtService.destroyToken();
  },
  [SET_USER](state: State, data: any) {
    state.user.firstName = data.first_name;
    state.user.lastName = data.last_name;
    state.user.company = data.company;
    state.user.username = data.username;
    state.user.notificationLanguage = data.notification_language;
    state.user.subscribed = data.subscribed;
    state.user.id = data.id;
    state.user.isStaff = data.is_staff;
    state.user.verificationCount = data.verification_count ? data.verification_count : 0;
    state.user.demo = data.hasOwnProperty('demo') ? data.demo : false;
    state.user.disabledChecks = data.disabled_checks;
    state.user.enableCommercialPrudence = data.enable_commercial_prudence;
    state.user.commercialPrudenceLimitEnabled = data.commercial_prudence_limit_enabled;
    state.user.commercialPrudenceLimit = data.commercial_prudence_limit;
    state.user.requiredVettingFields = data.required_vetting_fields;
    state.user.foreignDocuments = data.foreign_documents;
    if (data.id === 720) {
      state.user.canDoReports = 1;
    }
  },
  [SET_OFFICE_FUNCTION](state: State, data: any) {
    state.officeFunctionOptions = data;
  },
  [REDUCE_VERIFICATION](state: State, data: any) {
    state.user.verificationCount -= 1;
  },
  [ACCEPT_TERMS](state: State) {
    state.termsAccept = true;
  },
  [REFUSE_TERMS](state: State) {
    state.termsAccept = false;
  },
  [SET_REGISTRATION_FORM](state: State, data: any) {
    state.regFormData = {...state.regFormData, ...data};
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
