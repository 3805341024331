import Vue from 'vue';
import Vuex from 'vuex';
import auth from './auth.module';
import incidents from './incidents.module';
import visualization from './visualization.module';
import app from './app.module';
import analysis from './analysis.module';
import verification from './verification.module';
import admin from './admin.module';
import notification from './notification.module';
import filter from './filter.module';
import history from './history.module';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        app,
        auth,
        incidents,
        visualization,
        analysis,
        verification,
        admin,
        notification,
        filter,
        history,
    },
});
