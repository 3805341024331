import Vue from 'vue';
import Router from 'vue-router';
import PageNotFound from './views/PageNotFound.vue';
import store from '@/store';
import {CHECK_AUTH, FETCH_USER_INFO} from '@/store/actions.type';

Vue.use(Router);

const ifNotAuthenticated = (to: any, from: any, next: any) => {
  if (!store.getters.isAuthenticated) {
    next();
    return;
  }
  next('/');
};

const ifAuthenticated = (to: any, from: any, next: any) => {
  if (store.getters.isAuthenticated) {
    store.dispatch(CHECK_AUTH);
    next();
    return;
  }
  next('/login');
};

const isAdmin = (to: any, from: any, next: any) => {
  if (store.getters.isAuthenticated) {
    store.dispatch(CHECK_AUTH);
    if (store.getters.currentUser.isStaff) {
      next();
      return;
    }
    next('/');
    return;
  }
  next('/login');
};

const hasAdminAccess = (to: any, from: any, next: any) => {
  if (store.getters.isAuthenticated) {
    store.dispatch(CHECK_AUTH);
    if (store.getters.currentUser.isStaff || store.getters.currentUser.canDoReports) {
      next();
      return;
    }
    next('/');
    return;
  }
  next('/login');
};

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'mainPage',
      component: () => import(/* webpackChunkName: 'login' */ './views/MainPage.vue'),
      beforeEnter: ifAuthenticated,
      meta: { layout: 'basic-layout', name: 'mainPage' },
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: () => import(/* webpackChunkName: 'login' */ './views/MainPage.vue'),
      beforeEnter: ifAuthenticated,
      meta: { layout: 'basic-layout', name: 'dashboard' },
    },
    {
      path: '/news/:id',
      name: 'news',
      component: () => import(/* webpackChunkName: 'login' */ './views/NewsPage.vue'),
      beforeEnter: ifAuthenticated,
      meta: { layout: 'basic-layout', name: 'news' },
    },
    {
      path: '/login',
      name: 'login',
      beforeEnter: ifNotAuthenticated,
      component: () => import(/* webpackChunkName: 'login' */ './views/LoginPage.vue'),
      meta: { layout: 'login-layout' },
    },
    {
      path: '/recovery_password',
      name: 'recoveryPassword',
      beforeEnter: ifNotAuthenticated,
      component: () => import(/* webpackChunkName: 'login' */ './views/PasswordRecoveryPage.vue'),
      meta: { layout: 'login-layout' },
    },
    {
      path: '/registration_terms',
      name: 'terms',
      beforeEnter: ifNotAuthenticated,
      component: () => import(/* webpackChunkName: 'login' */ './views/TermsPage.vue'),
      props: { formType: 'terms' },
      meta: { layout: 'login-layout' },
    },
    {
      path: '/registration',
      name: 'registration',
      beforeEnter: ifNotAuthenticated,
      component: () => import(/* webpackChunkName: 'login' */ './views/RegistrationPage.vue'),
      meta: { layout: 'default-layout' },
    },
    {
      path: '/registrationPre',
      name: 'registrationPre',
      beforeEnter: ifNotAuthenticated,
      component: () => import(/* webpackChunkName: 'login' */ './views/RegistrationPre.vue'),
      meta: { layout: 'login-layout' },
    },
    // {
    //     path: '/incidents',
    //     name: 'incidents',
    //     redirect: '/incidents/page/1',
    //     beforeEnter: ifAuthenticated,
    // },
    // {
    //     path: '/incidents/page/',
    //     redirect: '/incidents/page/1',
    //     beforeEnter: ifAuthenticated,
    // },
    {
      path: '/incidents/view/:id',
      name: 'incidents:view',
      beforeEnter: ifAuthenticated,
      component: () => import(/* webpackChunkName: 'incidents' */ './views/IncidentPage.vue'),
      props: {edit: false},
    },
    {
      path: '/incidents/edit/:id',
      name: 'incidents:edit',
      beforeEnter: ifAuthenticated,
      component: () => import(/* webpackChunkName: 'incidents' */ './views/IncidentPage.vue'),
      props: {edit: true},
    },
    {
      path: '/analysis',
      name: 'analysis',
      beforeEnter: ifAuthenticated,
      redirect: '/analysis/cargo',
      meta: { name: 'analysis' },
    },
    {
      path: '/analysis/incidents',
      name: 'analysisIncidents',
      beforeEnter: ifAuthenticated,
      component: () => import(/* webpackChunkName: 'analysis' */ './views/AnalysisPageNew.vue'),
      meta: { layout: 'basic-layout', name: 'analysis' },
    },
    {
      path: '/analysis/geo',
      name: 'analysisGeo',
      beforeEnter: ifAuthenticated,
      component: () => import(/* webpackChunkName: 'analysis' */ './views/AnalysisPageNew.vue'),
      meta: { layout: 'basic-layout', name: 'analysis' },
    },
    {
      path: '/analysis/damage',
      name: 'analysisDamage',
      beforeEnter: ifAuthenticated,
      component: () => import(/* webpackChunkName: 'analysis' */ './views/AnalysisPageNew.vue'),
      meta: { layout: 'basic-layout', name: 'analysis' },
    },
    {
      path: '/analysis/cargo',
      name: 'analysisCargo',
      beforeEnter: ifAuthenticated,
      component: () => import(/* webpackChunkName: 'analysis' */ './views/AnalysisPageNew.vue'),
      meta: { layout: 'basic-layout', name: 'analysis' },
    },
    {
      path: '/analysis/rating',
      name: 'analysisRating',
      beforeEnter: ifAuthenticated,
      component: () => import(/* webpackChunkName: 'analysis' */ './views/AnalysisPageNew.vue'),
      meta: { layout: 'basic-layout', name: 'analysis' },
    },
    {
      path: '/incidents',
      name: 'incidents',
      beforeEnter: ifAuthenticated,
      component: () => import(/* webpackChunkName: 'incidents' */ './views/IncidentsPage.vue'),
      props: true,
      meta: { layout: 'basic-layout', name: 'incidents' },
    },
    {
      path: '/verification',
      name: 'verification',
      beforeEnter: ifAuthenticated,
      component: () => import(/* webpackChunkName: 'verification' */ './views/VerificationPage.vue'),
      meta: { layout: 'basic-layout', name: 'verification' },
    },
    {
      path: '/notification',
      name: 'notification',
      redirect: '/notification/page/1',
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/notification/page/',
      redirect: '/notification/page/1',
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/notification/page/:page',
      name: 'notification:page',
      beforeEnter: ifAuthenticated,
      component: () => import(/* webpackChunkName: 'login' */ './views/NotificationPage.vue'),
    },
    {
      path: '/services',
      name: 'services',
      component: () => import(/* webpackChunkName: 'login' */ './views/Services.vue'),
      beforeEnter: ifAuthenticated,
      meta: { layout: 'basic-layout', name: 'services' },
    },
    {
      path: '/support',
      name: 'support',
      component: () => import(/* webpackChunkName: 'login' */ './views/StubPage.vue'),
      beforeEnter: ifAuthenticated,
      meta: { layout: 'basic-layout', name: 'support' },
    },
    {
      path: '/faq',
      name: 'faq',
      beforeEnter: ifAuthenticated,
      component: () => import(/* webpackChunkName: 'incidents' */ './views/FAQ.vue'),
      props: true,
      meta: { layout: 'basic-layout', name: 'faq' },
    },
    {
      path: '/policy',
      name: 'policy',
      beforeEnter: ifAuthenticated,
      component: () => import(/* webpackChunkName: 'incidents' */ './views/PolicyPage.vue'),
      props: true,
      meta: { layout: 'basic-layout', name: 'policy' },
    },
    {
      path: '/administration',
      name: 'administration',
      component: () => import(/* webpackChunkName: 'administration' */ './views/AdminPage.vue'),
      beforeEnter: hasAdminAccess,
      // redirect: '/administration/users',
      meta: { layout: 'basic-layout', name: 'administration' },
    },
    {
      path: '/administration/users',
      name: 'administrationUsers',
      component: () => import(/* webpackChunkName: 'administration' */ './views/AdminPage.vue'),
      beforeEnter: isAdmin,
      meta: { layout: 'basic-layout', name: 'administration' },
    },
    {
      path: '/administration/api_users',
      name: 'administrationAPIUsers',
      component: () => import(/* webpackChunkName: 'administration' */ './views/AdminPage.vue'),
      beforeEnter: isAdmin,
      meta: { layout: 'basic-layout', name: 'administration' },
    },
    {
      path: '/administration/notifications',
      name: 'administrationNotifications',
      component: () => import(/* webpackChunkName: 'administration' */ './views/AdminPage.vue'),
      beforeEnter: isAdmin,
      meta: { layout: 'basic-layout', name: 'administration' },
    },
    {
      path: '/administration/verifications',
      name: 'administrationVerifications',
      component: () => import(/* webpackChunkName: 'administration' */ './views/AdminPage.vue'),
      beforeEnter: isAdmin,
      meta: { layout: 'basic-layout', name: 'administration' },
    },
    {
      path: '/administration/uploadincidents',
      name: 'administrationUploadIncidents',
      component: () => import(/* webpackChunkName: 'administration' */ './views/AdminPage.vue'),
      beforeEnter: isAdmin,
      meta: { layout: 'basic-layout', name: 'administration' },
    },
    {
      path: '/administration/reports',
      name: 'administrationReport',
      component: () => import(/* webpackChunkName: 'administration' */ './views/AdminPage.vue'),
      beforeEnter: hasAdminAccess,
      meta: { layout: 'basic-layout', name: 'administration' },
    },
    {
      path: '/administration/commercial_prudence',
      name: 'administrationCommercialPrudence',
      component: () => import(/* webpackChunkName: 'administration' */ './views/AdminPage.vue'),
      beforeEnter: hasAdminAccess,
      meta: { layout: 'basic-layout', name: 'administration' },
    },
    {
      path: '/user/:id',
      name: 'administrationUserSettings',
      component: () => import(/* webpackChunkName: 'administration' */'./views/UserSettingsPage.vue'),
      beforeEnter: isAdmin,
      meta: { layout: 'basic-layout', name: 'administration' },
    },
    {
      path: '/api_user/:id',
      name: 'administrationAPIUserSettings',
      component: () => import(/* webpackChunkName: 'administration' */'./views/APIUserSettingsPage.vue'),
      beforeEnter: isAdmin,
      meta: { layout: 'basic-layout', name: 'administration' },
    },
    {
      path: '/settings',
      name: 'settings',
      beforeEnter: ifAuthenticated,
      redirect: '/settings/profile',
      meta: { name: 'settings' },
    },
    {
      path: '/settings/profile',
      name: 'settingsProfile',
      beforeEnter: ifAuthenticated,
      component: () => import(/* webpackChunkName: 'login' */ './views/SettingsPage.vue'),
      meta: { layout: 'basic-layout', name: 'settings' },
    },
    {
      path: '/settings/notify',
      name: 'settingsNotify',
      beforeEnter: ifAuthenticated,
      component: () => import(/* webpackChunkName: 'login' */ './views/SettingsPage.vue'),
      meta: { layout: 'basic-layout', name: 'settings' },
    },
    {
      path: '/settings/security',
      name: 'settingsSecurity',
      beforeEnter: ifAuthenticated,
      redirect: '/settings/security/email',
      meta: { layout: 'basic-layout', name: 'settings' },
    },
    {
      path: '/settings/security/email',
      name: 'settingsSecurityEmail',
      beforeEnter: ifAuthenticated,
      component: () => import(/* webpackChunkName: 'login' */ './views/SettingsPage.vue'),
      meta: { layout: 'basic-layout', name: 'settings' },
    },
    {
      path: '/settings/security/password',
      name: 'settingsSecurityPassword',
      beforeEnter: ifAuthenticated,
      component: () => import(/* webpackChunkName: 'login' */ './views/SettingsPage.vue'),
      meta: { layout: 'basic-layout', name: 'settings' },
    },
    {
      path: '/password_reset',
      name: 'passwordReset',
      component: () => import(/* webpackChunkName: 'login' */ './views/PasswordResetPage.vue'),
      beforeEnter: ifNotAuthenticated,
      props: {formType: 'passwordReset'},
      meta: { layout: 'login-layout' },
    },
    {
      path: '/prudence',
      name: 'prudence',
      component: () => import(/* webpackChunkName: 'login' */ './views/CommercialPrudence.vue'),
      beforeEnter: ifAuthenticated,
      meta: { layout: 'basic-layout', name: 'prudence' },
    },
    {
      path: '/rating',
      name: 'rating',
      component: () => import(/* webpackChunkName: 'login' */ './views/Rating.vue'),
      beforeEnter: ifAuthenticated,
      meta: { layout: 'basic-layout', name: 'rating' },
    },
    {
      path: '/404',
      name: 'pageNotFound',
      component: PageNotFound,
      meta: { layout: 'login-layout' },
    },
    {
      path: '*',
      redirect: '/404',
    },
  ],
});

router.beforeEach(async (to: any, from: any, next: any) => {
  if (store.getters.isAuthenticated) {
    await store.dispatch(CHECK_AUTH);
    await store.dispatch(FETCH_USER_INFO);
  }
  next();
});

export default router;
