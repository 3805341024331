export const LOGIN = 'login';
export const REFRESH_TOKEN = 'refresh';
export const LOGOUT = 'logout';
export const CHECK_AUTH = 'checkAuth';
export const UPDATE_USER = 'updateUser';
export const FETCH_INCIDENTS = 'fetchIncidents';
export const FETCH_INCIDENT = 'fetchIncident';
export const FETCH_COORDINATES = 'fetchCoordinates';
export const FETCH_STATISTICS = 'fetchStatistics';
export const FETCH_REFERENCES = 'fetchReferences';
export const EXPORT_INCIDENTS = 'exportIncidents';
export const FETCH_INFO_TEXT = 'fetchInfoText';
export const SEND_FEEDBACK = 'sendFeedback';
export const FETCH_AUTO_VERIFICATION = 'fetchAutoVerification';
export const FETCH_OSAGO_VERIFICATION = 'fetchOsagoVerification';
export const FETCH_DRIVER_VERIFICATION = 'fetchDriverVerification';
export const FETCH_AUTOCOMPLETE_DATA = 'fetchAutocompleteData';
export const FETCH_RISK_VERIFICATION = 'fetchRiskVerification';
export const FETCH_CARGO_CATEGORY_DETAILS = 'fetchCargoCategoryDetails';
export const FETCH_REPUTATION_VERIFICATION = 'fetchReputationVerification';
export const FETCH_PARTICIPATION_VERIFICATION = 'fetchParticipationVerification';
export const REGISTRATION = 'registration';
export const FETCH_USER_INFO = 'fetchUserInfo';
export const FETCH_USERS = 'fetchUsers';
export const FETCH_USER_SETTINGS = 'fetchUserSettings';
export const CREATE_USER = 'createUser';
export const DELETE_USER = 'deleteUser';
export const FETCH_INCIDENT_REFERENCES = 'fetchIncidentReferences';
export const CREATE_INCIDENT = 'createIncident';
export const UPDATE_INCIDENT = 'updateIncident';
export const DELETE_INCIDENT = 'deleteIncident';
export const FETCH_OFFICE_FUNCTION = 'fetchOfficeFunction';
export const FETCH_VERIFICATION_TICKET = 'fetchVerificationTicket';
export const FETCH_VERIFICATION_TICKETS = 'fetchVerificationTickets';
export const SEND_NOTIFICATION = 'sendNotification';
export const FETCH_SEARCH_USER = 'fetchSearchUser';
export const UPDATE_VERIFICATIONS_COUNT = 'updateVerificationCount';
export const UPDATE_DISABLE_VERIFICATIONS = 'updateDisableVerifications';
export const UPDATE_PASSWORD = 'updatePassword';
export const UPDATE_PASSWORD_SELF = 'updatePasswordSelf';
export const RESET_PASSWORD = 'resetPassword';
export const UPDATE_SETTINGS = 'updateSettings';
export const UPDATE_SETTINGS_SELF = 'updateSettingsSelf';
export const SEND_PASSWORD_RECOVERY = 'sendPasswordRecovery';
export const FETCH_INCIDENTS_SUMMARY = 'fetchIncidentsSummary';
export const FETCH_NOTIFICATIONS = 'fetchNotifications';
export const FETCH_FILE = 'fetchFile';
export const FETCH_VERIFICATION_STATISTICS = 'fetchVerificationStatistics';
export const FETCH_UPLOADED_XLSX = 'fetchUploadedXlsx';
export const UPLOAD_XLSX = 'uploadXlsx';
export const UPLOAD_PASSPORT_SCAN = 'uploadPassportScan';
export const FETCH_ATI_SU_ID = 'fetchAtiSuID';
export const FETCH_PRUDENCE_VERIFICATION = 'fetchPrudenceVerification';
export const UPDATE_COMMERCIAL_PRUDENCE_LIMIT = 'updateCommercialPrudenceLimit';
