export const PURGE_AUTH = 'logOut';
export const SET_AUTH = 'setAuth';
export const SET_ERROR = 'setError';
export const SET_INCIDENTS = 'setIncidents';
export const SET_INCIDENT = 'setIncident';
export const FETCH_START = 'setLoading';
export const FETCH_END = 'setData';
export const PURGE_INCIDENT = 'purgeIncident';
export const SET_COORDINATES = 'setCoordinates';
export const SET_STATISTICS = 'setStatistics';
export const SET_REFERENCES = 'setReferences';
export const EXPORT_START = 'setExporting';
export const EXPORT_END = 'getFile';
export const PURGE_COORDINATES = 'purgeCoordinates';
export const SET_AUTO_VERIFICATION_DATA = 'setAutoVerificationData';
export const SET_OSAGO_VERIFICATION_DATA = 'setOsagoVerificationData';
export const FETCH_OSAGO_VERIFICATION_START = 'fetchOsagoVerificationStart';
export const FETCH_OSAGO_VERIFICATION_END = 'fetchOsagoVerificationEnd';
export const PURGE_AUTO_VERIFICATION_DATA = 'purgeAutoVerificationData';
export const PURGE_OSAGO_VERIFICATION_DATA = 'purgeOsagoVerificationData';
export const PURGE_DRIVER_VERIFICATION_DATA = 'purgeDriverVerificationData';
export const FETCH_DRIVER_VERIFICATION_START = 'setDriverDataLoading';
export const FETCH_DRIVER_VERIFICATION_END = 'setDriverData';
export const SET_DRIVER_VERIFICATION = 'setDriverVerification';
export const SET_AUTOCOMPLETE_DATA = 'setAutocompleteData';
export const SET_RISK_VERIFICATION_DATA = 'setRiskVerificationData';
export const FETCH_RISK_VERIFICATION_DATA_START = 'fetchRiskVerificationDataStart';
export const FETCH_RISK_VERIFICATION_DATA_END = 'fetchRiskVerificationDataEnd';
export const SET_USER = 'setUserInfo';
export const SET_USERS = 'setUsers';
export const SET_INCIDENT_REFERENCES = 'setIncidentReferences';
export const SET_OFFICE_FUNCTION = 'setOfficeFunction';
export const SET_VERIFICATION_TICKET = 'setVerificationTicket';
export const SET_VERIFICATION_TICKET_ERROR = 'setVerificationTicketError';
export const REDUCE_VERIFICATION = 'reduceVerification';
export const SET_VERIFICATION_ERROR = 'setVerificationError';
export const SET_SEARCH_USERS = 'setSearchUsers';
export const SET_USER_SETTINGS = 'setUserSettings';
export const SET_INCIDENTS_SUMMARY = 'setIncidentsSummary';
export const SET_CARGO_CATEGORY_DETAILS = 'setCargoCategoryDetails';
export const SET_FILTER_PARAMS = 'setFilterParams';
export const SET_FILTER_REFS = 'setFilterRefs';
export const SET_FILTER_VALUES = 'setFilterValues';
export const PREPARE_FILTER_REQUEST = 'prepareFilterRequest';
export const PURGE_FILTER_VALUES = 'purgeFilterValues';
export const SET_NOTIFICATIONS = 'setNotifications';
export const SET_VERIFICATION_STATISTICS = 'setVerificationStatistics';
export const SET_VERIFICATION_TICKETS = 'setVerificationTickets';
export const SET_UPLOADED_XLSX = 'setUploadedXlsx';
export const SET_UPLOADED_ERROR = 'setUploadedError';
export const SET_PASSPORT_DATA = 'setPassportData';
export const SET_NOTIFY = 'setNotify';
export const CLEAR_NOTIFY = 'clearNotify';
export const ACCEPT_TERMS = 'acceptTerms';
export const REFUSE_TERMS = 'refuseTerms';
export const SET_REGISTRATION_FORM = 'setREgistrationForm';
export const CLEAR_CHECK_FACE = 'clearCheckFace';
export const SET_INCIDENTS_STATS = 'setIncidentsStats';
export const SET_INCIDENTS_COUNT = 'setIncidentsCount';
export const SET_MAP_BOUNDS = 'setMapBounds';
export const DROP_PASSPORT_DATA = 'dropPassportData';
export const SET_ZERO_VERIFICATIONS_ERROR = 'setZeroVerificationsError';
export const CHANGE_CURRENT_DAMAGE_BAR_CHUNK = 'changeCurrentDamageBarChunk';
export const SET_CUSTOM_FILTER = 'setCustomFilter';
export const UPDATE_TRANSPORT_FORM = 'updateTransportForm';
export const CHANGE_TRANSPORT_FORM = 'changeTransportForm';
export const CLEAR_EMPTY_FIELDS = 'clearEmptyFields';
export const PURGE_REPUTATION_VERIFICATION_DATA = 'purgeReputationVerificationData';
export const FETCH_REPUTATION_VERIFICATION_START = 'fetchReputationVerificationStart';
export const FETCH_REPUTATION_VERIFICATION_END = 'fetchReputationVerificationEnd';
export const SET_REPUTATION_VERIFICATION_DATA = 'setReputationVerificationData';
export const PURGE_PARTICIPATION_VERIFICATION_DATA = 'purgeParticipationVerificationData';
export const FETCH_PARTICIPATION_VERIFICATION_START = 'fetchParticipationVerificationStart';
export const FETCH_PARTICIPATION_VERIFICATION_END = 'fetchParticipationVerificationEnd';
export const SET_PARTICIPATION_VERIFICATION_DATA = 'setParticipationVerificationData';
export const CLEAR_VERIFICATION_HISTORY = 'clearVerificationHistory';
export const PURGE_VERIFICATION_DATA = 'purgeVerificationData';
export const SET_FILTER_FIELD = 'setFilterField';
export const SET_PRUDENCE_VERIFICATION_DATA = 'setPrudenceVerificationData';
