import ApiService from '@/common/api.service';
import { FETCH_VERIFICATION_TICKETS } from '@/store/actions.type';
import {
    CLEAR_VERIFICATION_HISTORY,
    FETCH_END,
    FETCH_START,
    SET_ERROR,
    SET_VERIFICATION_TICKETS,
} from '@/store/mutations.type';


const state = {
    errors: null,
    tickets: [],
    ticketsCount: 0,
    ticketNextPage: null,
};

interface State {
    errors: any;
    tickets: object[];
    ticketsCount: number;
    ticketNextPage: string | null;
}

const getters = {
    tickets(state: State) {
        return state.tickets;
    },
    ticketsCount(state: State) {
        return state.ticketsCount;
    },
};

const actions = {
    async [FETCH_VERIFICATION_TICKETS](context: any, params: any = {}) {
        context.commit(FETCH_START);
        if (params.page === 1) {
            context.commit(CLEAR_VERIFICATION_HISTORY);
        }
        try {
            const resp: any = await ApiService.query('/impact/verification_ticket', params);
            context.commit(SET_VERIFICATION_TICKETS, resp.data);
        } catch (e) {
            context.commit(SET_ERROR, e);
        }
        context.commit(FETCH_END);
    },
};

const mutations = {
    [SET_VERIFICATION_TICKETS](state: State, data: any) {
        state.tickets = [...state.tickets].concat(data.results);
        state.ticketsCount = data.count;
        state.ticketNextPage = data.next;
    },
    [CLEAR_VERIFICATION_HISTORY](state: State) {
        state.tickets = [];
        state.ticketsCount = 0;
        state.ticketNextPage = null;
    },
};

export default {
    state,
    actions,
    mutations,
    getters,
};


