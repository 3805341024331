import ApiService from '@/common/api.service';
import {
    FETCH_AUTO_VERIFICATION, FETCH_DRIVER_VERIFICATION, FETCH_OSAGO_VERIFICATION, FETCH_RISK_VERIFICATION,
    FETCH_REPUTATION_VERIFICATION, FETCH_VERIFICATION_TICKET, UPLOAD_PASSPORT_SCAN, FETCH_ATI_SU_ID,
    FETCH_PARTICIPATION_VERIFICATION, FETCH_PRUDENCE_VERIFICATION,
} from './actions.type';
import {
    SET_AUTO_VERIFICATION_DATA,
    SET_ERROR,
    FETCH_START,
    FETCH_END,
    PURGE_AUTO_VERIFICATION_DATA,
    FETCH_DRIVER_VERIFICATION_START,
    FETCH_DRIVER_VERIFICATION_END,
    SET_DRIVER_VERIFICATION,
    SET_OSAGO_VERIFICATION_DATA,
    PURGE_OSAGO_VERIFICATION_DATA,
    FETCH_OSAGO_VERIFICATION_START,
    FETCH_OSAGO_VERIFICATION_END,
    SET_RISK_VERIFICATION_DATA,
    FETCH_RISK_VERIFICATION_DATA_START,
    FETCH_RISK_VERIFICATION_DATA_END,
    PURGE_REPUTATION_VERIFICATION_DATA,
    FETCH_REPUTATION_VERIFICATION_START,
    FETCH_REPUTATION_VERIFICATION_END,
    SET_REPUTATION_VERIFICATION_DATA,
    PURGE_PARTICIPATION_VERIFICATION_DATA,
    FETCH_PARTICIPATION_VERIFICATION_START,
    FETCH_PARTICIPATION_VERIFICATION_END,
    SET_PARTICIPATION_VERIFICATION_DATA,
    SET_VERIFICATION_TICKET, SET_VERIFICATION_TICKET_ERROR,
    REDUCE_VERIFICATION, SET_PASSPORT_DATA, CLEAR_CHECK_FACE,
    DROP_PASSPORT_DATA, SET_ZERO_VERIFICATIONS_ERROR,
    UPDATE_TRANSPORT_FORM, CHANGE_TRANSPORT_FORM,
    CLEAR_EMPTY_FIELDS, PURGE_VERIFICATION_DATA, SET_PRUDENCE_VERIFICATION_DATA,
} from './mutations.type';
import { convertDateToBackend, convertDateToDots, toBase64 } from '@/common/helpers';

const state = {
    verificationError: null,
    autoData: {error: false, data: [], atiInns: {}, foundAtiInns: [], atiLink: null, atiId: null},
    osagoData: {data: []},
    recognizeDriverData: {},
    driverData: {
        // osago: '',
        fssp: {},
        // birth_date: {},
        passport_complex: {},
        check_face: {},
        phone: {},
        driver: {},
        trust_person: {},
    },
    osagoDataIsLoading: false,
    driverDataIsLoading: {
        // osago: false,
        fssp: false,
        // birth_date: false,
        passport_complex: false,
        check_face: false,
        phone: false,
        driver: false,
        trust_person: false,
    },
    risk: {error: false, data: []},
    riskIsLoading: false,
    reputationData: {data: [], checked: false},
    reputationIsLoading: false,
    participationData: {data: {}, checked: false},
    participationIsLoading: false,
    verificationTicket: '',
    ticketError: false,
    transportFormData: {
      autoGos: '',
      autoVin: '',
      trailerGos: '',
      trailerVin: '',
      drivingLicenseNumber: '',
      drivingLicenseDate: '',
      lastName: '',
      firstName: '',
      midName: '',
      birthDate: '',
      passportNumber: '',
      passportDate: '',
      phone: '',
      ogrn: '',
      externalId: '',
      photo: '',
      inn: '',
  },
  transportFormSubmitted: false,
  emptyDriverLicense: [],
  emptyFieldsPhone: [],
  emptyFieldsPassport: [],
  emptyFieldsFssp: [],
  emptyFieldsOsago: [],
  emptyFieldsTransport: [],
  prudenceData: [],
};

interface State {
    autoData: any;
    verificationError: object | null;
    recognizeDriverData: object;
    driverDataIsLoading: any;
    driverData: any;
    osagoData: any;
    osagoDataIsLoading: boolean;
    risk: any;
    riskIsLoading: boolean;
    reputationData: any;
    reputationIsLoading: boolean;
    participationData: any;
    participationIsLoading: boolean;
    verificationTicket: string;
    ticketError: boolean;
    transportFormData: { [key: string]: string | any };
    transportFormSubmitted: boolean;
    emptyDriverLicense: string[];
    emptyFieldsPhone: string[];
    emptyFieldsPassport: string[];
    emptyFieldsFssp: string[];
    emptyFieldsOsago: string[];
    emptyFieldsTransport: string[];
    prudenceData: any[];
}

const getters = {
    autoData(state: State) {
        return state.autoData;
    },
    driverDataIsLoading(state: State) {
        return state.driverDataIsLoading;
    },
    driverData(state: State) {
        return state.driverData;
    },
    osagoData(state: State) {
        return state.osagoData;
    },
    osagoDataIsLoading(state: State) {
        return state.osagoDataIsLoading;
    },
    risk(state: State) {
        return state.risk;
    },
    riskIsLoading(state: State) {
        return state.riskIsLoading;
    },
    reputationData(state: State) {
        return state.reputationData;
    },
    reputationIsLoading(state: State) {
        return state.reputationIsLoading;
    },
    participationData(state: State) {
        return state.participationData;
    },
    participationIsLoading(state: State) {
        return state.participationIsLoading;
    },
    ticketError(state: State) {
        return state.ticketError;
    },
    verificationError(state: State) {
        return state.verificationError;
    },
    recognizeDriverData(state: State) {
        return state.recognizeDriverData;
    },
    transportFormData(state: State) {
        return state.transportFormData;
    },
    transportFormSubmitted(state: State) {
        return state.transportFormSubmitted;
    },
    emptyDriverLicense(state: State) {
        return state.emptyDriverLicense;
    },
    emptyFieldsPhone(state: State) {
        return state.emptyFieldsPhone;
    },
    emptyFieldsPassport(state: State) {
        return state.emptyFieldsPassport;
    },
    emptyFieldsFssp(state: State) {
        return state.emptyFieldsFssp;
    },
    emptyFieldsOsago(state: State) {
        return state.emptyFieldsOsago;
    },
    emptyFieldsTransport(state: State) {
        return state.emptyFieldsTransport;
    },
    prudenceDataRaw(state: State) {
        return state.prudenceData;
    },
};

const actions = {
    async [FETCH_VERIFICATION_TICKET](context: any, formData: any) {
        context.commit(FETCH_START);
        if (!context.rootState.auth.user.isStaff && context.rootState.auth.user.verification_count < 1) {
            context.commit(SET_VERIFICATION_TICKET_ERROR);
            return;
        }
        try {
            const preparedData = { ...formData };
            delete preparedData.photo;
            if (formData.photo) {
                const photo = await toBase64(formData.photo);
                preparedData.photo = { file: photo, name: formData.photo.name };
                preparedData.ticket = '2133213';
            }
            const resp: any = await ApiService.post('/impact/verification/get_ticket', preparedData);
            if (resp.status === 200) {
                context.commit(SET_VERIFICATION_TICKET, resp.data);
                if (!context.rootState.auth.user.isStaff) {
                    context.commit(REDUCE_VERIFICATION);
                }
            } else if (resp.status === 402) {
                context.commit(SET_ZERO_VERIFICATIONS_ERROR);
            } else {
                context.commit(SET_VERIFICATION_TICKET_ERROR);
            }
        } catch (e) {
            if (e.response.status === 402) {
                context.commit(SET_ZERO_VERIFICATIONS_ERROR);
            }
            context.commit(SET_VERIFICATION_TICKET_ERROR);
            context.commit(SET_ERROR, e);
        }
        context.commit(FETCH_END);
    },
    async [FETCH_AUTO_VERIFICATION](context: any, params: any = {}) {
        context.commit(PURGE_AUTO_VERIFICATION_DATA);
        let preparedParams = {...params};
        if (!Object.keys(preparedParams).filter( (key: any) => {
                return [
                    'autoGos', 'autoVin', 'trailerGos', 'trailerVin', 'ogrn', 'inn', 'externalId',
                ].includes(key) && String(preparedParams[key]).length;
            }).length) {
            return;
        }
        context.commit(FETCH_START);
        preparedParams.ticket = context.state.verificationTicket;
        preparedParams = prepareValues(preparedParams);
        try {
            const resp: any = await ApiService.post('/impact/verification/risk', preparedParams);
            context.commit(SET_AUTO_VERIFICATION_DATA, {respData: resp.data, formData: preparedParams});
        } catch (e) {
            context.commit(SET_ERROR, e);
        }
        context.commit(FETCH_END);
    },
    async [FETCH_OSAGO_VERIFICATION](context: any, params: any = {}) {
        if (!params.autoVin && !params.autoGos) {
            return;
        }
        context.commit(FETCH_OSAGO_VERIFICATION_START);
        context.commit(PURGE_OSAGO_VERIFICATION_DATA);
        let preparedParams: any = {
            vin: params.autoVin,
            licensePlate: params.autoGos,
        };
        preparedParams.ticket = context.state.verificationTicket;
        preparedParams = prepareValues(preparedParams);
        try {
            const resp: any = await ApiService.post('/impact/verification/osago', preparedParams);
            context.commit(SET_OSAGO_VERIFICATION_DATA, resp.data);
        } catch (e) {
            context.commit(SET_ERROR, e);
        }
        context.commit(FETCH_OSAGO_VERIFICATION_END);
    },
    async [FETCH_RISK_VERIFICATION](context: any, params: any) {
        context.commit(FETCH_RISK_VERIFICATION_DATA_START);
        let preparedParams = {...params};
        preparedParams.ticket = context.state.verificationTicket;
        preparedParams = prepareValues(preparedParams);
        try {
            const resp: any = await ApiService.post('/impact/verification/risk', preparedParams);
            context.commit(SET_RISK_VERIFICATION_DATA, resp.data);
        } catch (e) {
            context.commit(SET_ERROR, e);
        }
        context.commit(FETCH_RISK_VERIFICATION_DATA_END);
    },
    async [FETCH_DRIVER_VERIFICATION](context: any, params: any = {}) {
        const dataType = params.dataType;
        if (!Object.keys(context.state.driverData).includes(dataType)) {
            return;
        }
        let preparedData = Object.assign({}, params.formData);
        if (dataType === 'check_face') {
            if (!preparedData.photo) {
                return;
            }
            const tmp = new FormData();
            tmp.append('file',  preparedData.photo,  preparedData.photo.name);
            tmp.append('ticket', context.state.verificationTicket);
            preparedData = tmp;
        } else {
            delete preparedData.photo;
            if ('passport_complex' === dataType &&
                Object.keys(preparedData).filter( (key: any) => {
                    return [
                        'lastName', 'firstName', 'midName', 'birthDate', 'passportNumber', 'passportDate',
                    ].includes(key) && !String(preparedData[key]).length;
                }).length) {
                return;
            }
            if (('fssp' === dataType || 'trust_person' === dataType ) &&
                Object.keys(preparedData).filter( (key: any) => {
                    return [
                        'lastName', 'firstName', 'midName', 'birthDate',
                    ].includes(key) && !String(preparedData[key]).length;
                }).length) {
                return;
            }
            if (dataType === 'phone' &&
                Object.keys(preparedData).filter( (key: any) => {
                    return [
                        'lastName', 'firstName', 'birthDate', 'passportNumber', 'phone',
                    ].includes(key) && !String(preparedData[key]).length;
                }).length) {
                return;
            }
            if (dataType === 'driver' &&
                Object.keys(preparedData).filter( (key: any) => {
                    return [
                        'drivingLicenseNumber', 'drivingLicenseDate',
                    ].includes(key) && !String(preparedData[key]).length;
                }).length) {
                return;
            }
            if (preparedData.hasOwnProperty('passportDate')) {
                preparedData.issueDate = preparedData.passportDate;
            }
            preparedData = prepareValues(preparedData);
            preparedData.ticket = context.state.verificationTicket;
        }
        context.commit(PURGE_VERIFICATION_DATA);
        context.commit(FETCH_DRIVER_VERIFICATION_START, dataType);
        try {
            let resp: any = {};
            if (dataType === 'check_face') {
                resp = await ApiService.postFile(`/impact/verification/check_face`, preparedData);
            } else {
                resp = await ApiService.post(`/impact/verification/${dataType}`, preparedData);
            }
            const data = resp.data;
            context.commit(SET_DRIVER_VERIFICATION, {dataType, data});
        } catch (e) {
            context.commit(SET_ERROR, 'Извините, возникла ошибка.');
        }
        context.commit(FETCH_DRIVER_VERIFICATION_END, dataType);
    },
    async [UPLOAD_PASSPORT_SCAN](context: any, passportScan: any) {
        if (!passportScan) {
            return;
        }
        context.commit(FETCH_START);
        const formData = new FormData();
        formData.append('file',  passportScan,  passportScan.name);
        try {
            const resp: any = await ApiService.postFile(`/impact/verification/recognize_passport`, formData);
            context.commit(SET_PASSPORT_DATA, resp.data);
        } catch (e) {
            context.commit(SET_ERROR, 'Извините, возникла ошибка.');
        }
        context.commit(FETCH_END);
    },
    async [FETCH_ATI_SU_ID](context: any, inn: string) {
        if (!inn) {
            return;
        }
        context.commit(FETCH_START);
        const resp = await ApiService.query('/impact/verification/find_ati_su_id', {inn});
        if (resp.data.ati_su_id) {
            state.transportFormData.externalId = resp.data.ati_su_id;
        }
        context.commit(FETCH_END);
    },
    async [FETCH_REPUTATION_VERIFICATION](context: any, inn: string) {
        if (!inn) {
            return;
        }
        context.commit(FETCH_REPUTATION_VERIFICATION_START);
        context.commit(PURGE_REPUTATION_VERIFICATION_DATA);
        const resp = await ApiService.post('/impact/verification/reputation',
            {inn, ticket: context.state.verificationTicket});
        context.commit(SET_REPUTATION_VERIFICATION_DATA, resp.data);
        context.commit(FETCH_REPUTATION_VERIFICATION_END);
    },
    async [FETCH_PARTICIPATION_VERIFICATION](context: any, params: any = {}) {
        if (!params.firstName || !params.lastName || !params.midName || !params.passportNumber) {
            return;
        }
        context.commit(FETCH_PARTICIPATION_VERIFICATION_START);
        context.commit(PURGE_PARTICIPATION_VERIFICATION_DATA);
        try {
            const resp: any = await ApiService.post('/impact/verification/participation',
                {firstName: params.firstName, lastName: params.lastName, midName: params.midName,
                    passportNumber: params.passportNumber, ticket: context.state.verificationTicket});
            context.commit(SET_PARTICIPATION_VERIFICATION_DATA, resp.data);
        } catch (e) {
            context.commit(SET_ERROR, e);
        }
        context.commit(FETCH_PARTICIPATION_VERIFICATION_END);
    },
    async [FETCH_PRUDENCE_VERIFICATION](context: any, params: any) {
        context.commit(FETCH_RISK_VERIFICATION_DATA_START);
        let preparedParams = { ...params };
        preparedParams.ticket = context.state.verificationTicket;
        preparedParams = prepareValues(preparedParams);
        context.commit(SET_PRUDENCE_VERIFICATION_DATA, []);
        try {
            const resp: any = await ApiService.post('/impact/verification/prudence', preparedParams);
            context.commit(SET_PRUDENCE_VERIFICATION_DATA, resp.data.data);
        } catch (e) {
            const err = { ...e };
            if (e.response.status === 402 || e.response.status === 403) {
                err.limitSpent = true;
            }
            context.commit(SET_ERROR, err);
        }
        context.commit(FETCH_RISK_VERIFICATION_DATA_END);
    },
};

const prepareValues = (data: any) => {
    const newData = { ...data };
    ['passportNumber', 'phone', 'autoGos', 'trailerGos', 'licensePlate'].map((field: any) => {
        if (newData.hasOwnProperty(field)) {
            newData[field] = newData[field].replace(/ /g, '');
        }
    });
    ['drivingLicenseDate', 'birthDate', 'passportDate'].map((field: any) => {
        if (newData.hasOwnProperty(field)) {
            newData[field] = convertDateToBackend(newData[field]);
        }
    });
    // if (newData.hasOwnProperty('issueDate')) {
    //     newData.issueDate = convertDateToDots(newData.issueDate);
    // }
    return newData;
};

const mutations = {
    [SET_VERIFICATION_TICKET](state: State, data: any) {
        state.verificationTicket = data.ticket;
    },
    [SET_VERIFICATION_TICKET_ERROR](state: State, data: any) {
        state.ticketError = true;
    },
    [SET_AUTO_VERIFICATION_DATA](state: State, data: any) {
        if (data.respData.resultCode < 0) {
            state.autoData = {error: true, msg: data.respData.resultMessage};
        } else {
            let resultClass = 'success';
            data.respData.list.map( (row: any) => {
                if (resultClass !== 'alert') {
                    if (data.formData.externalId && row.externalId &&
                            row.externalId.includes(data.formData.externalId)) {
                        resultClass = 'warning';
                    }
                    if (data.formData.ogrn && row.ogrn && row.ogrn.includes(data.formData.ogrn)) {
                        resultClass = 'warning';
                    }
                    if (data.formData.inn && row.inn && row.inn.includes(data.formData.inn)) {
                        resultClass = 'warning';
                    }
                    ['autoGos', 'autoVin', 'trailerGos', 'trailerVin'].map((field) => {
                        if (data.formData[field] && row[field] && data.formData[field] === row[field]) {
                            resultClass = 'alert';
                        }
                    });
                }
            });
            state.autoData = {error: false, data: data.respData.list, atiInns: data.respData.ati_inns, resultClass,
                atiLink: data.respData.ati_link, atiId: data.respData.ati_id};
        }
    },
    [PURGE_AUTO_VERIFICATION_DATA](state: State, data: any) {
        state.autoData = {error: false, data: [], checked: false};
    },
    [PURGE_OSAGO_VERIFICATION_DATA](state: State, data: any) {
        state.osagoData = {data: []};
    },
    [PURGE_REPUTATION_VERIFICATION_DATA](state: State, data: any) {
        state.reputationData = {data: [], checked: false};
    },
    [PURGE_PARTICIPATION_VERIFICATION_DATA](state: State, data: any) {
        state.participationData = {data: {}, checked: false};
    },
    [FETCH_DRIVER_VERIFICATION_START](state: State, dataType: string) {
        state.driverDataIsLoading[dataType] = true;
    },
    [FETCH_DRIVER_VERIFICATION_END](state: State, dataType: string) {
        state.driverDataIsLoading[dataType] = false;
    },
    [FETCH_OSAGO_VERIFICATION_END](state: State) {
        state.osagoDataIsLoading = false;
    },
    [FETCH_OSAGO_VERIFICATION_START](state: State) {
        state.osagoDataIsLoading = true;
    },
    [SET_OSAGO_VERIFICATION_DATA](state: State, data: any) {
        if (data.resultCode < 0) {
            state.osagoData = {error: true, msg: data.resultMessage, data: [], osagoExpired: false};
        } else {
            let osagoExpired = false;
            if (data.policies.length > 0) {
                osagoExpired = true;
                for (const policy of data.policies) {
                    if (policy.status === 'Действует') {
                        osagoExpired = false;
                    }
                }
            }
            state.osagoData = {error: false, data: data.policies, osagoExpired};
        }
    },
    [FETCH_RISK_VERIFICATION_DATA_START](state: State) {
        state.riskIsLoading = true;
    },
    [FETCH_RISK_VERIFICATION_DATA_END](state: State) {
        state.riskIsLoading = false;
    },
    [SET_RISK_VERIFICATION_DATA](state: State, data: any) {
        if (data.resultCode < 0 ) {
            state.risk = {error: true, msg: data.resultMessage};
        } else {
            state.risk = {error: false, data: data.list};
        }
    },
    [FETCH_REPUTATION_VERIFICATION_START](state: State) {
        state.reputationIsLoading = true;
    },
    [FETCH_REPUTATION_VERIFICATION_END](state: State) {
        state.reputationIsLoading = false;
    },
    [SET_REPUTATION_VERIFICATION_DATA](state: State, data: any) {
        state.reputationData = {data: (typeof data.Items !== 'undefined') ? data.Items : [], checked: true};
    },
    [FETCH_PARTICIPATION_VERIFICATION_START](state: State) {
        state.participationIsLoading = true;
    },
    [FETCH_PARTICIPATION_VERIFICATION_END](state: State) {
        state.participationIsLoading = false;
    },
    [SET_PARTICIPATION_VERIFICATION_DATA](state: State, data: any) {
        state.participationData = {data, checked: true};
    },
    [SET_DRIVER_VERIFICATION](state: State, data: any) {
        const respData = data.data;
        let verificationData = null;
        switch (data.dataType) {
            case 'passport_complex':
                if (respData.resultCode === 0) {
                    verificationData = {msg: respData.description, score: respData.score, resultCode: 0, checked: true};
                } else {
                    verificationData = {msg: respData.resultMessage, score: respData.score ? respData.score : 0,
                        resultCode: respData.resultCode, checked: true};
                }
                break;
            case 'phone':
                if (respData.resultCode === 0) {
                    verificationData = {msg: respData.operationMessage,
                        confirmed: respData.operationResult === 'success'};
                } else {
                    verificationData = {msg: respData.resultMessage, confirmed: false};
                }
                break;
            case 'birth_date':
                if (respData.resultCode === 0) {
                    verificationData = respData.birthDate;
                } else {
                    verificationData = respData.resultMessage;
                }
                break;
            case 'fssp':
                if (respData.resultCode === 0) {
                    let sum = 0;
                    respData.data.forEach( (element: any) => {
                        sum += element.sum;
                    });
                    verificationData = {error: false, found: respData.found, data: respData.data};
                } else {
                    verificationData = {error: true, msg: respData.resultMessage, resultClass: 'alert'};
                }
                break;
            case 'trust_person':
                if (respData.resultCode === 0) {
                    let resultClass = 'success';
                    //  розыск, группа риска
                    if (respData.c1.indexOf('RED') > -1 || respData.c2.indexOf('RED') > -1 ||
                            respData.c3.indexOf('RED') > -1) {
                        resultClass = 'alert';
                    } else if (respData.c4.indexOf('RED') > -1 || respData.c5.indexOf('RED') > -1 ||
                            respData.c6.indexOf('RED') > -1) {
                        resultClass = 'warning';
                    }
                    verificationData = { found: true, data: respData, resultClass };
                } else if (respData.resultCode === -100) {
                    verificationData = { found: false, msg: respData.resultMessage };
                } else {
                    verificationData = { found: false, error: true, msg: respData.resultMessage };
                }
                break;
            case 'check_face':
                if (respData.message) {
                    verificationData = {error: true, msg: respData.message};
                } else {
                    verificationData = {found: !!respData.matches.length, data: respData.matches,
                        uploadedImage: respData.uploaded_face };
                }
                break;
            case 'driver':
                if (respData.resultCode === 0) {
                    const drivingLicenseDate = new Date(respData.drivingLicenseExpiryDate.substring(6),
                        respData.drivingLicenseExpiryDate.substring(3, 5),
                        respData.drivingLicenseExpiryDate.substring(0, 2));
                    let drivingLicenseExpired = true;
                    if (drivingLicenseDate >= new Date() || (drivingLicenseDate >= new Date(2022, 1, 1) && drivingLicenseDate <= new Date(2025, 12, 31) && new Date() <= new Date(2028, 12, 31))) {
                        drivingLicenseExpired = false;
                    }
                    verificationData = {
                        wanted: respData.wanted,
                        drivingLicenseExpired,
                        decisionList: respData.decisionList ? respData.decisionList : [],
                        categories: respData.drivingLicenseCategory,
                        status: respData.status,
                        valid: !respData.wanted && respData.status === 'Действует' && !drivingLicenseExpired &&
                                !respData.decisionList.filter(
                            ( elem: any ) =>
                                elem.state && elem.state.toLowerCase().includes('лишени')).length,
                    };
                } else {
                    verificationData = {error: true, msg: respData.resultMessage, match: false};
                }
                break;
        }
        state.driverData[data.dataType] = verificationData;
    },
    [SET_PASSPORT_DATA](state: State, data: any) {
        if (data.documents && data.documents.length) {
            const preparedData: any = {};
            const fieldRelation: {[key: string]: string} = {
                last_name: 'lastName',
                first_name: 'firstName',
                middle_name: 'midName',
                birth_date: 'birthDate',
                serial_number: 'passportNumber',
                issue_date: 'passportDate',
            };
            Object.keys(fieldRelation).map((item: string) => {
                if (data.documents[0].fields[item] && data.documents[0].fields[item].text) {
                    preparedData[fieldRelation[item]] = data.documents[0].fields[item].text;
                    if (['birth_date', 'issue_date'].indexOf(item) === -1) {
                        preparedData[fieldRelation[item]] = preparedData[fieldRelation[item]][0].toUpperCase() +
                            preparedData[fieldRelation[item]].slice(1);
                    }
                }
            });
            state.recognizeDriverData = { data: preparedData };
        } else {
            state.recognizeDriverData = { error: true, msg: data.message };
        }
    },
    [DROP_PASSPORT_DATA](state: State) {
        state.recognizeDriverData = {};
    },
    [SET_ZERO_VERIFICATIONS_ERROR](state: State) {
       alert('У Вас закончились проверки');
    },
    [CLEAR_CHECK_FACE](state: State) {
        state.driverData.check_face = {};
    },
    [PURGE_VERIFICATION_DATA](state: State) {
        state.driverData = {
            // osago: '',
            fssp: {},
            // birth_date: {},
            passport_complex: {},
            check_face: {},
            phone: {},
            driver: {},
            trust_person: {},
        };
    },
    [UPDATE_TRANSPORT_FORM](state: State, params: any) {
        state.transportFormData = {...state.transportFormData, ...params};
    },
    [CHANGE_TRANSPORT_FORM](state: State, submitted: boolean) {
        state.transportFormSubmitted = submitted;
    },
    [CLEAR_EMPTY_FIELDS](state: State) {
        state.emptyFieldsPassport = [];
        state.emptyFieldsFssp = [];
        state.emptyFieldsPhone = [];
        state.emptyDriverLicense = [];
        state.emptyFieldsTransport = [];
        state.emptyFieldsOsago = [];
    },
    [SET_PRUDENCE_VERIFICATION_DATA](state: State, data: any) {
        state.prudenceData = [...data];
    },
};

export default {
    state,
    actions,
    mutations,
    getters,
};
