
import { Component, Vue } from 'vue-property-decorator';
import BasicFooter from '@/components/BasicFooter.vue';
import SideBar from '@/components/SideBar.vue';
import BasicHeader from '@/components/BasicHeader.vue';
import SvgInclude from '@/components/SvgInclude.vue';

@Component({
  components: { BasicHeader, SideBar, BasicFooter, SvgInclude },
})
export default class BasicLayout extends Vue {
  private showMenu: boolean = false;
  get showFooter() {
    return this.$route.name !== 'prudence' && this.$route.name !== 'incidents';
  }

  private switchMobileMenu() {
    this.showMenu = !this.showMenu;
  }
}
