import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import JwtService, {refreshExp} from '@/common/jwt.service';
import store from '@/store';
import router from '@/router';
import { REFRESH_TOKEN } from '@/store/actions.type';
import { PURGE_AUTH } from '@/store/mutations.type';

/* tslint:disable:no-console */

const ApiService = {
    init() {
        Vue.use(VueAxios, axios);
        Vue.axios.defaults.baseURL = `/api/v1`;

        Vue.axios.interceptors.request.use(async (config: any) => {
            if (process.env.VUE_APP_API_DEBUG_PARAM) {
                if (config.method === 'get') {
                    const newParams = Object.assign({}, config.params);
                    newParams.debug = 1;
                    config.params = newParams;
                } else if (config.method !== 'delete') {
                    config.data.debug = 1;
                }
            }
            if (config.url.indexOf('/auth/') > -1 || config.url.indexOf('/registration') > -1 ||
                config.url.indexOf('/office_function') > -1 || config.url.indexOf('/password_recovery') > -1 ||
                config.url.indexOf('/field_of_activity') > -1) {
                return config;
            }
            const diffTime: number = JwtService.getTimeFromToken(JwtService.getToken()) - Math.round(Date.now() / 1000);
            let setLogout = false;
            if (diffTime < 60) {
                if (JwtService.refreshExp() > 0 ) {
                    try {
                        config = await this.refreshToken(config);
                    } catch (e) {
                        setLogout = true;
                    }
                } else {
                    setLogout = true;
                }
            }
            if (setLogout) {
                store.commit(PURGE_AUTH);
                router.push({ name: 'login' });
            }
            return config;
        });

        Vue.axios.interceptors.response.use((response) => {
            return response;
        }, async (error) => {
            const { response: { status }, config } = error;
            if (config.url.indexOf('/auth/') > -1 || config.url.indexOf('/registration') > -1 ||
                config.url.indexOf('/office_function') > -1 || config.url.indexOf('/password_recovery') > -1 ||
                config.url.indexOf('/field_of_activity') > -1) {
                return Promise.reject(error);
            }
            if (status === 401 ) {
                store.commit(PURGE_AUTH);
                router.push({ name: 'login' });
                return new Promise((resolve) => {
                    resolve(axios(config));
                });
            } else if (config.url.indexOf('/impact/incident') > -1 && status === 404) {
                router.push({ name: 'pageNotFound' });
            }
            return Promise.reject(error);
        });
    },

    setHeader() {
        Vue.axios.defaults.headers.common.Authorization = `Bearer ${JwtService.getToken()}`;
    },

    async query(resource: string, params: object = {}) {
        // try {
            return await Vue.axios.get(resource, {params});
        // } catch (e) {
        //     console.error(e);
        // }
    },

    async getFile(resource: string, params: object = {}) {
        try {
            return await Vue.axios.get(resource, {responseType: 'blob', params});
        } catch (e) {
            console.error(e);
        }
    },

    async get(resource: string, slug: string = '') {
        // try {
            return await Vue.axios.get(`${resource}/${slug}`);
        // } catch (e) {
        //     console.error(e);
        // }
    },

    async post(resource: string, params: object = {}) {
        // try {
        return await Vue.axios.post(`${resource}`, params);
        // } catch (e) {
        //     console.error(e);
        // }
    },

    async postFile(resource: string, params: object = {}) {
        try {
            return await Vue.axios.post(`${resource}`, params, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
        } catch (e) {
            console.error(e);
        }
    },

    async update(resource: string, slug: string = '', params: object = {}) {
        return await Vue.axios.put(`${resource}/${slug}`, params);
    },

    async put(resource: string, params: object = {}) {
        return await Vue.axios.put(`${resource}`, params);
    },

    async delete(resource: string) {
        return await Vue.axios.delete(resource);
    },

    async refreshToken(config: any) {
        await store.dispatch(REFRESH_TOKEN, JwtService.getRefreshToken());
        config.headers.Authorization = `Bearer ${JwtService.getToken()}`;
        this.setHeader();
        return config;
    },
};

export default ApiService;
