
import {Component, Prop, Vue} from 'vue-property-decorator';
import {mapGetters} from 'vuex';

@Component({
  computed: mapGetters(['currentUser']),
})

export default class SideBar extends Vue {
  public currentUser!: any;
  get dashItems() {
    return [
      {title: this.$t('strings.analysis'), icon: 'ic_other__donut', route: 'analysis'},
      {title: this.$t('strings.verification'), icon: 'ic_basic__search', route: 'verification'},
      {title: this.$t('strings.incidents'), icon: 'ic_other__layers', route: 'incidents'},
      {title: this.$tc('strings.commercialPrudenceInLogistics'), icon: 'ic_map__directions', route: 'prudence',
        disable: !this.currentUser.enableCommercialPrudence},
      {title: this.$t('strings.services'), icon: 'ic_docs__bookmark_empty', route: 'services'},
      {title: this.$t('strings.rating'), icon: 'ic_other__list', route: 'rating'},
      // {title: this.$t('titles.adminPanel'), icon: 'ic_other__lock', route: 'administration'},
    ];
  }
}
