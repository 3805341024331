
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class TheFooter extends Vue {
  get year() {
    const currentDate = new Date();
    return currentDate.getFullYear();
  }
}
