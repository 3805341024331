import ApiService from '@/common/api.service';
import {
    FETCH_COORDINATES, FETCH_REFERENCES, FETCH_AUTOCOMPLETE_DATA,
} from './actions.type';
import {
    SET_COORDINATES,
    SET_ERROR,
    FETCH_START,
    FETCH_END,
    SET_REFERENCES,
    PURGE_COORDINATES,
    SET_AUTOCOMPLETE_DATA,
    SET_INCIDENTS_SUMMARY,
    SET_FILTER_PARAMS,
    SET_MAP_BOUNDS,
    PREPARE_FILTER_REQUEST,
    SET_CUSTOM_FILTER,
} from './mutations.type';


const state = {
    errors: null,
    coordinates: [],
    fieldRefs: {
        attackMethod: {},
        cargoCategory: {},
        cargoDescription: {},
        incidentCategory: {},
        informationSource: {},
        federalDistrict: {},
    },
    filterOptions: {},
    filterLoader: false,
    filterParams: {
        cargo_categories: [],
        incident_categories: [],
    },
    searchAutocomplete: [],
    mapBounds: {south: 0, west: 0, north: 0, east: 0},
};

interface State {
    errors: object | null;
    coordinates: object[];
    fieldRefs: object;
    filterOptions: object[];
    filterLoader: boolean;
    filterParams: {
        cargo_categories: number[],
        incident_categories: number[],
    };
    searchAutocomplete: string[];
    mapBounds: any;
}

const getters = {
    coordinates(state: State) {
        return state.coordinates;
    },
    filterOptions(state: State) {
        return state.filterOptions;
    },
    fieldRefs(state: State) {
        return state.fieldRefs;
    },
    filterParams(state: State) {
        return state.filterParams;
    },
    searchAutocomplete(state: State) {
        return state.searchAutocomplete;
    },
    mapBounds(state: State) {
        return state.mapBounds;
    },
};

const actions = {
    async [FETCH_COORDINATES](context: any, params: any = {}) {
        const { filterLoader } = params;
        context.commit(FETCH_START, 'coordinates');
        context.commit(PURGE_COORDINATES);
        if (Object.keys(params).length > 0) {
          context.commit(SET_CUSTOM_FILTER, params);
        } else {
          context.commit(PREPARE_FILTER_REQUEST);
        }
        try {
            const resp: any = await ApiService.query('/impact/incident/get_all_coordinates',
                                                     context.rootState.filter.filterRequest);
            context.commit(SET_COORDINATES, resp.data);
            if (Object.keys(params).length === 0) {
              context.commit(SET_INCIDENTS_SUMMARY, resp.data.summary);
              context.commit(SET_FILTER_PARAMS, resp.data);
            }
        } catch (e) {
            context.commit(SET_ERROR, e);
        }
        context.commit(FETCH_END, 'coordinates');
    },
    async [FETCH_REFERENCES](context: any) {
        // context.commit(FETCH_START);
        try {
            const resp: any = await ApiService.query('/impact/incident/get_references');
            context.commit(SET_REFERENCES, resp.data);
        } catch (e) {
            context.commit(SET_ERROR, e);
        }
        // context.commit(FETCH_END);
    },
    async [FETCH_AUTOCOMPLETE_DATA](context: any, query: {s: string, lang: string}) {
        try {
            const resp: any = await ApiService.query('/impact/incident/search_helper', query);
            context.commit(SET_AUTOCOMPLETE_DATA, resp.data);
        } catch (e) {
            context.commit(SET_ERROR, e);
        }
    },
};

const mutations = {
    [SET_ERROR](state: State, error: object) {
        state.errors = error;
    },
    [PURGE_COORDINATES](state: State) {
        state.coordinates = [];
    },
    [SET_COORDINATES](state: State, data: any) {
        state.coordinates = data.items;
        state.fieldRefs = {
            attackMethod: data.attack_method,
            cargoCategory: data.cargo_category,
            cargoDescription: data.cargo_description,
            incidentCategory: data.incident_category,
            informationSource: data.information_source,
            federalDistrict: data.federal_district,
        };
    },
    [SET_FILTER_PARAMS](state: State, data: any) {
        state.filterParams = {
            cargo_categories: data.cargo_categories,
            incident_categories: data.incident_categories,
        };
    },
    [SET_REFERENCES](state: State, data: any) {
        state.filterOptions = data;
    },
    [SET_AUTOCOMPLETE_DATA](state: State, data: any) {
        state.searchAutocomplete = data.result.map( (category: any) => category[0]);
    },
    [SET_MAP_BOUNDS](state: State, data: any) {
        state.mapBounds = data;
    },
};

export default {
    state,
    actions,
    mutations,
    getters,
};
