
import { Component, Vue, Prop } from 'vue-property-decorator';
import { SEND_FEEDBACK } from '@/store/actions.type';
import { mapGetters } from 'vuex';
import { setLocale } from '@/common/locale.service';
import { CLEAR_NOTIFY, PURGE_AUTH } from '@/store/mutations.type';

@Component({
  computed: mapGetters(['isAuthenticated', 'currentUser', 'currentNotify']),
})
export default class Header extends Vue {

  private isAuthenticated!: boolean;
  private currentNotify!: boolean | string;
  private isStaff!: boolean;
  private feedback: string = '';
  private modalShow: boolean = false;
  private showLangSwitch: boolean = false;

  public LogOut(event: any) {
    this.$store.commit(PURGE_AUTH);
    this.$router.push({name: 'login'});
  }

  public changeLocale(locale: string) {
    setLocale(locale);
    this.showLangSwitch = false;
    this.$i18n.locale = locale;
  }

  private closeFeedbackForm() {
    this.modalShow = false;
    this.feedback = '';
  }

  private async sendFeedback() {
    await this.$store.dispatch(SEND_FEEDBACK, this.feedback);
    this.modalShow = false;
  }

  private clearNotify() {
    this.$store.commit(CLEAR_NOTIFY);
  }
}
