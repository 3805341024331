
import { Component, Vue } from 'vue-property-decorator';
import Header from '@/components/Header.vue';
import TheFooter from '@/components/TheFooter.vue';
import SvgInclude from '@/components/SvgInclude.vue';

@Component({
  components: { SvgInclude, TheFooter, Header },
})
export default class LoginLayout extends Vue {
}
