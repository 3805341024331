export const messages = {
    en: {
        errors: {
            emptyFieldError: 'This field can\'t be empty',
            invalidLogin: 'Invalid login or password.',
            pageNotFound: 'Page not found.',
            fillOneField: 'Fill at least one field.',
            fillAllFields: 'Fill all required fields',
            errorHasOccurred: 'Sorry, an error has occurred.',
            passwordsDoNotMatch: 'Passwords do not match',
        },
        formLabels: {
            username: 'Username',
            password: 'Password',
            logIn: 'Log in',
            logOut: 'Log out',
        },
        buttons: {
            back: 'Back',
        },
        strings: {
            vettingResult: 'Result of vetting',
            checked: 'Checked',
            checkedNeutral: 'Checked',
            notChecked: 'Not checked',
            risk: 'Risk:',
            highRisk: 'High risk',
            midRisk: 'Middle risk',
            lowRisk: 'Low risk',
            additionalActRec: 'Additional verification activities are recommended.',
            notEnoughData: 'Not enough data',
            enterMoreParameters: 'Enter more search parameters',
            valid: 'Valid',
            notCarriedOut: 'Not carried out',
            driverLicense: 'Driver\'s license',
            autoIncidents: 'Vehicle involvement in incidents',
            fioPhone: 'Bundle name - Phone',
            debtsFSSP_: 'Debts under the FSSP',
            faceIncidentsParticipation: 'Participation of person in incidents',
            driverIncidentsParticipation: 'Participation of driver in incidents',
            osagoPolis: 'Polis OSAGO',
            requestDocuments: 'Request documents',
            enterUsername: 'Enter username',
            enterPassword: 'Enter password',
            lang: 'Language',
            incidents: 'Incidents',
            incident: 'no incidents | {n} incident | {n} incidents | {n} incidents',
            incidentWord: 'no incidents | incident | incidents | incidents',
            visualization: 'Visualization',
            analysis: 'Analysis',
            date: 'Date',
            time: 'Time',
            chooseTime: 'Choose time',
            category: 'Category',
            description: 'Description',
            attackMethod: 'Type of incident',
            attackPlace: 'Place',
            indexHeader: '#',
            incidentInformation: 'Incident information',
            membersRegisteredAtExchange: 'Members registred at exchange',
            informationSource: 'Information source',
            referenceDate: 'Reference date',
            cargoInformation: 'Cargo information',
            amount: 'Amount',
            price: 'Price',
            type: 'Type',
            country: 'Country',
            region: 'Region',
            oblast: 'Oblast',
            city: 'City',
            street: 'Street',
            road: 'Road',
            coordinates: 'Coordinates',
            route: 'Route | Routes',
            commercialPrudenceInLogistics: 'Commercial Prudence in Logistics',
            pointOfDeparture: 'Point of departure',
            destination: 'Destination',
            vehicleInfo: 'Vehicle information',
            haveVehicleInfo: 'Have vehicle information',
            haveOgrn: 'Have OGRN',
            tractorLicensePlate: 'Truck license plate',
            tractorVin: 'Truck VIN',
            trailerLicensePlate: 'Trailer license plate',
            trailerVin: 'Trailer VIN',
            driver: 'Driver',
            verifiedDrivers: 'Verified drivers',
            driversDocument: 'Driver\'s ID',
            photo: 'Driver\'s ID',
            upload: 'Upload',
            uploadTitle: 'Document image in .jpg or .png format',
            uploadsList: 'List of recent uploads',
            uploadsError: 'An error occurred while uploading the file, please try again later.',
            dateUpload: 'Upload date',
            dateImport: 'Import date',
            notifySent: 'Notify sent.',
            notifyNew: 'New notify',
            notifyAllUsers: 'To all users',
            notifySelectedUsers: 'To selected users',
            notifySelectUsers: 'Select users',
            notifyNotFound: 'Nothing found.',
            notifyText: 'Message text',
            notifySelectType: 'Select the type of recipient.',
            notifyEnterText: 'Enter your notify text.',
            notifyEnterSubject: 'Enter a subject for the notify.',
            notifyError: 'An error has occurred.',
            addUserForm: 'Add user',
            addUserFormPass: 'The password will be generated automatically and sent to the user by e-mail.',
            deleteUserForm: 'Are you sure you want to delete the user?',
            haveDriverId: 'Have driver ID',
            participantInCourtProceedings: 'Participant in court proceedings',
            haveDriverPhoto: 'Have driver photo',
            controlRiskId: 'Control risk ID',
            map: 'Map',
            verification: 'Vetting',
            verifications: 'Vettings',
            newVetting: 'New vetting',
            verification_history: 'Vetting history',
            verification_history_done: 'Vettings done:',
            verification_history_of: 'of',
            driverIncidents: 'Driver incidents:',
            vehicleIncidents: 'Vehicle incidents:',
            result: 'Result:',
            verification_remaining: 'Remaining vetting:',
            low: 'Low',
            mid: 'Not enough data',
            high: 'High',
            incidentCategory: 'Modus Operandi',
            goodsCategory: 'Goods category',
            other: 'Other',
            undefinedAttackMethod: 'not defined',
            undefinedLocation: 'not defined',
            numberOfIncidents: 'Total incidents',
            numberOfIncidentsOnMap: 'Incidents on map',
            amountOfDamage: 'Amount of lost value',
            averageCostOfStolen: 'Average cost of stolen',
            searchByGoods: 'Search by goods',
            search: 'Search',
            searchByProfileFields: 'Search by first/last name, company, phone, email',
            reset: 'Reset',
            exportExcel: 'Export incidents to Excel',
            undefined: 'Undefined',
            list: 'List',
            feedback: 'Feedback',
            send: 'Send',
            cancel: 'Cancel',
            yourMessage: 'Your message',
            ogrn: 'OGRN / OGRNIP',
            autoLicensePlate: 'Auto license plate',
            autoVin: 'Auto VIN',
            externalId: 'ATI ID',
            externalIdList: 'List of ATI Exchange Identifiers',
            judNum: 'Number of judicial decisions',
            smiDate: 'Date mentioned in the media',
            noDataFound: 'Not found',
            verificationAuto: 'Vetting of vehicle',
            verificationDriver: 'Vetting of driver',
            clear: 'Clear',
            firstName: 'First name',
            lastName: 'Last name',
            middleName: 'Middle name',
            midName: 'Middle name',
            birthDate: 'Date of Birth',
            registrationDate: 'Registration date',
            passportNumber: 'Passport series and number',
            passportDate: 'Passport date of issue',
            snils: 'SNILS',
            phone: 'Phone',
            email: 'Email',
            notFound: 'Not found',
            postcode: 'Post code',
            drivingLicenseNumber: 'Driving license number',
            passportNumberTip: 'Passport series and number together without separators',
            autoLicensePlateTip: 'Auto license plate, only digits and cyrillic letters together without separators',
            trailerLicensePlateTip: 'Trailer license plate, only digits and cyrillic letters together without ' +
                'separators',
            vinTip: 'VIN, only digits and latin letters together without separators',
            externalIdTip: 'ATI Identifier, only digits, at least 5 digits',
            noRisk: 'no risk',
            chooseScanFile: 'Upload passport scan',
            passportScan: 'Passport scan',
            recognize: 'Recognize',
            chooseFile: 'Choose file',
            dropFile: 'Drop file here...',
            missingPassportScan: 'Missing passport scan',
            missingPhoto: 'Missing driver photo',
            missing: 'Missing',
            missingPhotoInDB: 'Driver photo not in photo bank',
            noOsagoVerification: 'The check was not performed (no driver license number entered)',
            noOsagoVerificationAll: 'The check was not performed (no driver license number and auto license plate or ' +
                'VIN entered)',
            noOsagoVerificationAuto: 'The check was not performed (no auto license plate or VIN entered)',
            cargoCategory: 'Cargo category',
            cargoType: 'Cargo type',
            cost: 'Cost',
            fromDate: 'from',
            toDate: 'to',
            fromPrice: 'from',
            toPrice: 'to',
            selectedPeriod: 'Selected period',
            yearEarlier: 'Year earlier',
            selectAll: 'Select all',
            forwarders: 'Forwarders',
            transport: 'Transport',
            noVerificationFields: 'The check was not performed (not enough fields: {fields})',
            drivingLicenseDate: 'Date of issue of driver\'s license',
            noVerificationFieldsAtLeastOne: 'The check was not performed (enter at least one field: {fields})',
            noVerificationExternalId: 'The check was not performed (ATI Identifier should be at least 5 digits)',
            signIn: 'Sign In',
            signUp: 'Sign Up',
            position: 'Position',
            company: 'Company name',
            registration: 'Registration',
            users: 'Users',
            user: 'User',
            addUser: 'Add',
            ipAddress: 'IP address',
            create: 'Create',
            exist: 'Exist',
            createNewAccount: 'Create New Account',
            allowedCharacters: 'Latin letters, numbers and symbols "._" are allowed',
            account: 'Account',
            delete: 'Delete',
            attention: 'Attention',
            actions: 'Actions',
            yes: 'Yes',
            no: 'No',
            edit: 'Edit',
            officeFunction: 'Office function',
            officeFunctions: 'Office functions',
            usersManual: 'User\'s manual',
            newPassword: 'New password',
            repeatPassword: 'Repeat password',
            userSettings: 'User settings',
            changePassword: 'Change password',
            saved: 'Saved',
            save: 'Save',
            forgotPassword: 'Forgot your password',
            recoverMsg: 'Enter email',
            ok: 'Ok',
            recoverMsgSuccess: 'If the email exists in the system, you will receive a link to reset your password.',
            recoveryMsg: 'An email with a password reset instruction has been sent to the indicated email address.',
            wrongKey: 'Wrong key or key is out of date.',
            passwordChanged: 'Password updated successfully. You can now',
            partnershipIDX: 'The service is organized in partnership with IDX.',
            subject: 'Subject',
            noNotifications: 'No notifications',
            showFull: 'Show full',
            viewFile: 'View file',
            notificationLanguage: 'Notification language',
            receiveNewsletters: 'Receive newsletters',
            fullName: 'Name',
            exportExcelDisabled: 'Download Excel file is not available in demo mode',
            incidentsUpload: 'Incidents upload',
            accessLevel: 'Access level',
            more: 'More...',
            limitedAccess: 'Limited access',
            onlySouthern: 'In limited access, only incidents of the South Region are displayed.',
            viewOnlyInFullAccess: 'View this incident is available in full access.',
            driverPhoto: 'Driver image',
            eventDate: 'Event date',
            loadingAddress: 'Loading Address',
            unloadingAddress: 'Unloading address',
            photoInfo: 'Download any image of the driver (passport, VU, photo from the camera) for comparison with ' +
                'the photobank of persons who participated in incidents with total or partial loss of cargo.',
            uploadedImage: 'Uploaded image',
            foundImage: 'Found image',
            coincidence: 'Coincidence',
            recovery: 'Recovery',
            createAccount: 'Create account',
            haveNoAccount: 'Don\'t have an account yet?',
            yourFirstName: 'Your first name',
            yourLastName: 'Your last name',
            services: 'Services',
            rating: 'Rating',
            passport: 'Passport',
            passportIssued: 'Passport issued',
            noData: 'no data',
            filterFormGeoAll: 'All locations',
            allCountries: 'All countries',
            filterFormPeriodAll: 'All time',
            filterFormCargoAll: 'All cargo categories',
            allIncidentCategories: 'All Modus Operandi',
            fieldOfActivity: 'Field of activity',
            truckAndTrailer: 'Truck and trailer',
            drivingLicense: 'Driver\'s license',
            forwarder: 'Forwarder',
            licensePlateTractor: 'License plate of truck',
            autoGos: 'License plate of truck',
            seriesAndNumber: 'Series and number',
            licensePlateTrailer: 'License plate of trailer',
            trailerGos: 'License plate of trailer',
            verificationAgree: 'By clicking on the "Check" button, I confirm that I received the personal data of an ' +
                'individual in a legal way and have the legal right to transfer this data to third parties in order ' +
                'to verify this data',
            enterSearchCriteria: 'Enter search criteria',
            recognizePassport: 'Recognize passport',
            recognizeDocumentData: 'Recognize data of uploaded document',
            notValid: 'Not valid',
            verified: 'Verified',
            decisionStatus: 'Decision status',
            status: 'Status',
            driversLicenseFull: 'Driver\' license',
            period: 'Period',
            licenseOwnerBirthPlace: 'Place of birth of license holder',
            comment: 'Comment',
            requestHistory: 'Request history',
            incidentsNotFound: 'No incidents',
            incidentsFoundFew: 'Found one or two incidents',
            incidentsFoundMany: 'Found 3 or more incidents',
            uploaded: 'Uploaded',
            check: 'Check',
            connectionNamePhone: 'Connection: Name - Phone',
            confirmedF: 'Confirmed',
            notConfirmedF: 'Not confirmed',
            insurancePolicy: 'Insurance policy',
            insuranceCompany: 'Insurance company',
            policySerial: 'Policy serial',
            policyNumber: 'Policy number',
            policyIsRestrict: 'Policy is restrict',
            policyNoRestrict: 'No restrictions',
            policyYesRestrict: 'With restrictions',
            uniqueNum: 'Unique number',
            bodyNumber: 'Body number',
            regNumber: 'Reg number',
            markAuto: 'Mark auto',
            modelAuto: 'Model auto',
            createDate: 'Create date',
            startDate: 'Start date',
            endDate: 'End date',
            participationFaceLong: 'Participation of person in incidents related to full or partial loss of cargo',
            participationDriverLong: 'Participation of driver in incidents related to full or partial loss of cargo',
            number: 'Nr.',
            loadingUnloadingAddress: 'Loading and unloading address',
            cargoBrCategory: 'Cargo<br/>category',
            info: 'Info',
            source: 'Source',
            participationVehicleLong: 'Participation of a vehicle in incidents related to full or partial loss ' +
                'of cargo',
            trustworthinessScoring: 'Trustworthiness scoring',
            debtsFSSP: 'Debts under the FSSP in the data bank of enforcement proceedings',
            companyInfo: 'Information about company',
            debtExpired: 'Expired',
            error: 'Error',
            debtorInformation: 'Debtor information',
            enforcementProceedings: 'Enforcement proceedings (number, date)',
            detailsOfEP: 'Details of enforcement proceedings',
            terminationEP: 'End or termination date and reason',
            subjectOfExecution: 'Subject of execution',
            amountOfOutstandingDebt: 'Amount of outstanding debt',
            infoBailiriffs: 'Information about the department of bailiffs and bailiffs',
            go: 'More details',
            moreDetails: 'More details',
            analysisText: 'Helps to assess risks and identify new trends in the activities of criminal gangs',
            incidentsText: 'Real-time picture of cargo thefts throughout Russia',
            verificationText: 'Vetting of the driver, vehicle and forwarders of transportation to reduce risks',
            routes: 'Routes',
            support: 'Support',
            logisticsProcessAudit: 'Logistics process audit',
            verificationCriteria: 'Entered {count} of {total} search criteria',
            cargoCategories: 'Cargo categories',
            typesOfIncidents: 'Types of incidents',
            geographyOfIncidents: 'Geography of incidents',
            damageAmount: 'Lost value',
            selectedCategories: 'Selected categories',
            russia: 'Russia',
            allTime: 'All time',
            allCargoCategories: 'All cargo categories',
            allDamage: 'All lost value',
            found: 'Found',
            incidentsFilter: 'Incidents filter',
            incidentsSearch: 'Search incidents',
            apply: 'Apply',
            incidentsPeriod: 'Period of incidents',
            downloadExcel: 'Download in Excel format',
            filter: 'Filter',
            priceRange_0_100K: '0 - 100K ₽',
            priceRange_100K_300K: '100K - 300K ₽',
            priceRange_300K_500K: '300K - 500K ₽',
            priceRange_500K_1M: '500K - 1M ₽',
            priceRange_1M_2M: '1M - 2M ₽',
            priceRange_2M_3M: '2M - 3M ₽',
            priceRange_3M_5M: '3M - 5M ₽',
            priceRange_5M_10M: '5M - 10M ₽',
            priceRange_10M_25M: '10M - 25M ₽',
            priceRangeOver25M: '> 25M ₽',
            fromTo: 'From {from} to {to}',
            totalDamage: 'Total lost value',
            thousands: 'K',
            millions: 'mln',
            billions: 'bln',
            incidentsByFD: 'Incidents by region',
            top3Incidents: 'Top 3 incidents',
            byAttackMethods: 'By type of incident',
            detailsByCategory: 'Details by category',
            geography: 'Geography',
            incidentsCount: 'Incidents',
            typeOfScene: 'Incident location',
            lastIncidents: 'Last incidents',
            unidentified: 'Unidentified',
            damageByFederalDistricts: 'damageByFederalDistricts',
            federalDistrict: 'federal district',
            characteristicIncident: 'Characteristic incident',
            top10Cities: 'TOP 10 LOCATIONS WITH THE MOST INCIDENTS',
            top10Regions: 'TOP 10 REGIONS WITH THE MOST INCIDENTS',
            lossByMonth: 'Loss by month',
            theftInTheParkingLot: 'Theft in the parking lot',
            robbery: 'Robbery',
            fraud: 'Fraud',
            cargoValue: 'Cargo value',
            mainPage: 'Main',
            damage: 'Damage',
            registrationOnTransportExchangeOfAll: 'Registration on the transport exchange of all forwarders in ' +
                'the episode',
            serviceWillBeAvailableSoon: 'The service will be available soon',
            routesLongText: 'The IMPACT service for building safe routes makes it possible to form a route taking ' +
                'into account safe parking places and the presence of incidents in the immediate vicinity of the ' +
                'route borders for the selected category of cargo. Each route can be supplemented with special ' +
                'instructions for drivers, indicate the necessary restrictions, contacts of responsible persons and ' +
                'phone numbers in case of emergencies',
            ofSafeParkings: 'safe parking lots',
            consultingServices: 'Consulting services',
            servicesLongText: 'In addition to the main online services, IMPACT provides its clients with a whole ' +
                'range of consulting services. Our experts have many years of experience in international and ' +
                'Russian companies and have the most advanced knowledge and practices in the field of supply chain ' +
                'security. We conduct audits of warehouses, suppliers, business processes, quantify risks by route, ' +
                'cost-effectiveness for security and optimal delivery methods, develop safety requirements and ' +
                'instructions for shippers and carriers, and carry out certification according to various standards, ' +
                'including TAPA and ISO.',
            transportCompaniesRating: 'Transport companies rating',
            ratingLongText: 'Based on an in-depth analysis of the decisions of arbitration courts regarding ' +
                'forwarders in incidents involving complete or partial loss of cargo, data from electronic portals ' +
                'of cargo transportation, as well as leading services for checking counterparties, IMPACT forms an ' +
                'index of due diligence and reliability of transport companies. The information provided by IMPACT ' +
                'includes the affiliation and relationships of the founders, financial losses / losses as a result ' +
                'of cargo theft, compliance with the declared activity and other parameters.',
            settings: 'Settings',
            profile: 'Profile',
            notifications: 'Notifications',
            security: 'Security',
            about: 'About',
            confidentiality: 'Confidentiality',
            contacts: 'Contacts',
            helpMessageForFillingOutForm: 'Help message for filling out the form',
            receiveNotificationsByEmail: 'Receive notifications by e-mail',
            currentEmail: 'Current email',
            newEmail: 'New email',
            currentPassword: 'Current password',
            enterCurrentPassword: 'Enter current password',
            enterNewPassword: 'Enter new password',
            repeatNewPassword: 'Repeat new password',
            emailChanged: 'E-mail changed.',
            currentPasswordWrong: 'Current password is wrong.',
            passwordChanged1: 'Password changed.',
            inn: 'INN',
            continueMsg: 'Continue',
            congratulations: 'Congratulations',
            accountCreatedConfirm: 'Your account has been successfully created and is being verified. You will ' +
                'receive a login link from the administrator to your email address in the near future.',
            privacyPolicy: 'Privacy policy',
            alreadyHaveAccount: 'Already have account?',
            cargo: 'Cargo',
            inc: 'Inc.',
            logoutMsgConfirm: 'Are you sure you want to quit IMPACT?',
            month: 'mo.',
            year: '{n} yr. | {n} yr. | {n} yr. | {n} yr.',
            total: 'Total',
            all: 'All',
            full: 'Full',
            limited: 'Limited',
            detectAtiCode: 'Detect ATI.SU code',
            atiHint: 'To determine the ATI code, you must enter the TIN of the legal entity',
            driverLossEpisodesFound: 'Found episodes of lost cargo involving this driver.' ,
            driverLossEpisodesNotFound: 'No episodes of lost cargo were found involving this driver.',
            expired: 'Expired',
            passwordChanged2: 'Your password has been successfully changed. Use your new password to sign in to your ' +
                'account.',
            reports: 'Reports',
            reportVerificationsSpak: 'SPAC verifications report',
            get: 'Get',
            cplSubscription: 'CPL subscrition',
            client: 'Client',
            numberOfINN: 'Number of INN',
            subscriptionsList: 'Subscriptions list',
            viewResults: 'View results',
            commercialPrudenceInLogisticsSubscription: 'Commercial Prudence in Logistics subscription',
            newMatch: 'New match | New matches',
            oldMatch: 'Old match | Old Matches',
            notificationSentTo: 'Notification sent to',
            matches: 'Matches',
            new: 'New',
            foreignDocuments: 'Foreign documents',
        },
        titles: {
            analysisByTime: 'Analysis by time parameters',
            countByMonth: 'Number of incidents by month',
            countByWeekday: 'Distribution by day of the week',
            analysisByRegion: 'Territorial analysis',
            analysisByGoods: 'Analysis by product category',
            analysisByMisc: 'Analysis by type of crime, methods and places of theft',
            countByDistricts: 'Number of incidents in the federal district of the Russian Federation',
            top15regions: '15 regions with the highest number of reported incidents',
            top20cities: 'Top 20 cities with the highest number of reported incidents',
            distributionByCargoCategory: 'Distribution of shares by category of goods',
            incidentCategoryChart: 'Chart by type of crime',
            attachMethodsChart: 'Chart on methods of attacking loads',
            typeOfSceneChart: 'Chart by accident',
            incidentsList: 'Incidents list',
            addIncident: 'Add incident',
            byGoodsPrice: 'Distribution by product price',
            notification: 'Notification',
            notifications: 'Notifications',
            news: 'News',
            services: 'Services',
            adminPanel: 'Admin',
            restorePassword: 'Restore password',
        },
        systemMessages: {
            loading: 'Loading...',
        },
        months: {
            1: 'january', 2: 'february', 3: 'march', 4: 'april', 5: 'may', 6: 'june', 7: 'july', 8: 'august',
            9: 'september', 10: 'october', 11: 'november', 12: 'december',
        },
        days: {
            0: 'monday', 1: 'tuesday', 2: 'wednesday', 3: 'thursday', 4: 'friday', 5: 'saturday', 6: 'sunday',
        },
    },
    ru: {
        errors: {
            emptyFieldError: 'Поле не может быть пустым',
            invalidLogin: 'Неверный логин или пароль.',
            pageNotFound: 'Страница не найдена.',
            fillOneField: 'Заполните хотя бы одно поле.',
            fillAllFields: 'Заполните обязательные поля.',
            errorHasOccurred: 'Извините, произошла ошибка.',
            passwordsDoNotMatch: 'Пароли не совпадают.',
        },
        formLabels: {
            username: 'Имя пользователя',
            password: 'Пароль',
            logIn: 'Войти',
            logOut: 'Выйти',
        },
        buttons: {
            back: 'Назад',
        },
        strings: {
            vettingResult: 'Результат проверки',
            checked: 'Проверен',
            checkedNeutral: 'Проверено',
            notChecked: 'Не проверен',
            risk: 'Риск:',
            highRisk: 'Высокий уровень риска',
            midRisk: 'Средний уровень риска',
            lowRisk: 'Низкий уровень риска',
            additionalActRec: 'Рекомендуется проведение дополнительных проверочных мероприятий.',
            notEnoughData: 'Не хватает данных',
            enterMoreParameters: 'Введите больше параметров для поиска',
            valid: 'Действителен',
            notCarriedOut: 'Не проведено',
            driverLicense: 'Водительское удостоверение',
            autoIncidents: 'Участие ТС в инцидентах',
            fioPhone: 'Связка ФИО - Телефон',
            debtsFSSP_: 'Задолженности по ФССП',
            faceIncidentsParticipation: 'Участие лица в инцидентах',
            driverIncidentsParticipation: 'Участие водителя в инцидентах',
            osagoPolis: 'Полис ОСАГО',
            requestDocuments: 'Запросить документы',
            enterUsername: 'Введите имя пользователя',
            enterPassword: 'Введите пароль',
            incidents: 'Инциденты',
            incident: 'нет инцидентов | {n} инцидент | {n} инцидента | {n} инцидентов',
            incidentWord: 'нет инцидентов | инцидент | инцидента | инцидентов',
            visualization: 'Визуализация',
            analysis: 'Аналитика',
            date: 'Дата',
            time: 'Время',
            chooseTime: 'Выберите время',
            category: 'Категория',
            description: 'Описание',
            attackMethod: 'Способ нападения',
            attackPlace: 'Место',
            indexHeader: '№',
            incidentInformation: 'Информация об инциденте',
            membersRegisteredAtExchange: 'Наличие регистрации на транспортной бирже всех участников эпизода',
            informationSource: 'Источник информации',
            cargoInformation: 'Информация о грузе',
            referenceDate: 'Дата упоминания',
            amount: 'Количество',
            price: 'Cтоимость',
            type: 'Тип',
            country: 'Страна',
            region: 'Регион',
            oblast: 'Область',
            city: 'Населенный пункт',
            street: 'Улица',
            road: 'Трасса',
            coordinates: 'Координаты',
            route: 'Маршрут | Маршруты',
            commercialPrudenceInLogistics: 'Коммерческая осмотрительность в логистике',
            pointOfDeparture: 'Пункт отправления',
            destination: 'Пункт назначения',
            vehicleInfo: 'Информация о транспортном средстве',
            haveVehicleInfo: 'Наличие информации о транспортном средстве',
            haveOgrn: 'Наличие информации об ОГРН участников',
            tractorLicensePlate: 'Гос. знак тягача',
            tractorVin: 'VIN тягача',
            trailerLicensePlate: 'Гос. знак прицепа',
            trailerVin: 'VIN прицепа',
            driver: 'Водитель',
            driversDocument: 'Документ водителя',
            photo: 'Документ водителя',
            verifiedDrivers: 'Проверенные водители',
            upload: 'Загрузить',
            uploadTitle: 'Загрузить изображение документа в формате .jpg или .png',
            uploadsList: 'Список последних загрузок',
            uploadsError: 'При загрузке файла возникла ошибка, попробуйте позднее.',
            dateUpload: 'Дата загрузки',
            dateImport: 'Дата импорта',
            notifySent: 'Оповещение отправлено.',
            notifyNew: 'Новое оповещение',
            notifyAllUsers: 'Всем пользователям',
            notifySelectedUsers: 'Выбранным получателям',
            notifySelectUsers: 'Выберите получателей',
            notifyNotFound: 'Ничего не найдено.',
            notifyText: 'Текст сообщения',
            notifySelectType: 'Выберите тип получателя.',
            notifyEnterText: 'Введите текст оповещения.',
            notifyEnterSubject: 'Введите тему оповещения.',
            notifyError: 'Произошла ошибка.',
            addUserForm: 'Добавить пользователя',
            addUserFormPass: 'Пароль будет сгенерирован автоматически и отправлен пользователю на e-mail.',
            deleteUserForm: 'Вы действительно хотите удалить пользователя?',
            haveDriverId: 'Идентификатор водителя',
            participantInCourtProceedings: 'Участник судебных разбирательств',
            haveDriverPhoto: 'Имеется ли фото водителя',
            controlRiskId: '№ в таблице КР',
            map: 'Карта',
            verification: 'Проверка',
            verifications: 'Проверки',
            newVetting: 'Новая проверка',
            verification_history: 'История запросов',
            verification_history_done: 'Сделано проверок:',
            verification_history_of: 'из',
            driverIncidents: 'Инцидентов водителя:',
            vehicleIncidents: 'Инцидентов ТС:',
            result: 'Результат:',
            low: 'Низкий',
            mid: 'Не хватает данных',
            high: 'Высокий',
            verification_remaining: 'Осталось запросов:',
            incidentCategory: 'Категория инцидента',
            goodsCategory: 'Категория товара',
            other: 'Другое',
            undefinedAttackMethod: 'не установлено',
            undefinedLocation: 'не установлено',
            numberOfIncidents: 'Всего инцидентов',
            numberOfIncidentsOnMap: 'Инцидентов на карте',
            amountOfDamage: 'Сумма ущерба',
            averageCostOfStolen: 'Средняя стоимость украденного',
            searchByGoods: 'Поиск по товарам',
            search: 'Поиск',
            searchByProfileFields: 'Поиск по имени/фамилии, компании, телефону, email',
            reset: 'Сбросить',
            exportExcel: 'Выгрузить инциденты в Excel',
            undefined: 'не определено',
            list: 'Список',
            feedback: 'Обратная связь',
            send: 'Отправить',
            cancel: 'Отмена',
            yourMessage: 'Ваше сообщение',
            ogrn: 'ОГРН / ОГРНИП',
            autoLicensePlate: 'Госномер автомобиля',
            autoVin: 'VIN автомобиля',
            externalId: 'Код в АТИ',
            externalIdList: 'Идентификаторы биржи АТИ',
            judNum: 'Номер судебного решения',
            smiDate: 'Дата упоминания в СМИ',
            noDataFound: 'Не найдено',
            verificationAuto: 'Проверка авто',
            verificationDriver: 'Проверка водителя',
            clear: 'Очистить',
            firstName: 'Имя',
            lastName: 'Фамилия',
            middleName: 'Отчество',
            midName: 'Отчество',
            birthDate: 'Дата рождения',
            registrationDate: 'Дата регистрации',
            passportNumber: 'Номер паспорта',
            passportDate: 'Дата выдачи паспорта',
            snils: 'СНИЛС',
            phone: 'Телефон',
            email: 'Электронная почта',
            notFound: 'Не найдено',
            postcode: 'Индекс',
            drivingLicenseNumber: 'Серия и номер водительского удостоверения',
            passportNumberTip: 'Серия и номер паспорта слитно без разделителей',
            autoLicensePlateTip: 'Госномер автомобиля, только кириллица и цифры слитно без разделителей',
            trailerLicensePlateTip: 'Госномер прицепа, только кириллица и цифры слитно без разделителей',
            vinTip: 'VIN, только цифры и латинские буквы слитно без разделителей',
            externalIdTip: 'Идентификатор АТИ, только цифры, не менее 5 цифр',
            noRisk: 'нет риска',
            chooseScanFile: 'Загрузите скан паспорта',
            passportScan: 'Скан паспорта',
            recognize: 'Распознать',
            chooseFile: 'Выберите файл',
            dropFile: 'Перетащите файл сюда...',
            missingPassportScan: 'Отсутствует скан паспорта',
            missingPhoto: 'Отсутствует фотография водителя',
            missing: 'Отсутствует',
            noOsagoVerification: 'Проверка не выполнялась (не введен номер ВУ)',
            noOsagoVerificationAll: 'Проверка не выполнялась (не введен номер ВУ и госномер авто либо VIN)',
            noOsagoVerificationAuto: 'Проверка не выполнялась (не введен госномер авто либо VIN)',
            cargoCategory: 'Категория груза',
            cargoType: 'Тип груза',
            cost: 'Стоимость',
            fromDate: 'с',
            toDate: 'по',
            fromPrice: 'от',
            toPrice: 'до',
            selectedPeriod: 'Выбранный период',
            yearEarlier: 'Годом ранее',
            selectAll: 'Выбрать все',
            forwarders: 'Участники',
            transport: 'Транспорт',
            noVerificationFields: 'Проверка не выполнялась (не хватает полей: {fields})',
            drivingLicenseDate: 'Дата выдачи водительского удостоверения',
            noVerificationFieldsAtLeastOne: 'Проверка не выполнялась (введите хотя бы одно из полей: {fields})',
            noVerificationExternalId: 'Проверка не выполнялась (Идентификатор АТИ должен содержать минимум 5 цифр)',
            signIn: 'Вход',
            signUp: 'Регистрация',
            position: 'Должность',
            company: 'Название компании',
            registration: 'Регистрация',
            users: 'Пользователи',
            user: 'Пользователь',
            addUser: 'Добавить',
            ipAddress: 'IP-адрес',
            create: 'Создать',
            exist: 'Есть',
            createNewAccount: 'Создание новой учетной записи',
            allowedCharacters: 'Допустимы латинские буквы, цифры и символы "._"',
            account: 'Учетная запись',
            delete: 'Удалить',
            attention: 'Внимание',
            actions: 'Действия',
            yes: 'Да',
            no: 'Нет',
            edit: 'Редактировать',
            officeFunction: 'Функция',
            officeFunctions: 'Функции',
            usersManual: 'Руководство пользователя',
            newPassword: 'Новый пароль',
            repeatPassword: 'Повторите пароль',
            userSettings: 'Настройки пользователя',
            changePassword: 'Смена пароля',
            saved: 'Сохранено',
            save: 'Сохранить',
            forgotPassword: 'Забыли пароль',
            recoverMsg: 'Введите email',
            ok: 'Ок',
            recoverMsgSuccess: 'Если e-mail существует в системе, Вы получите ссылку на восстановление пароля.',
            recoveryMsg: 'На указанный электронный адрес отправлено письмо с инструкцией по восстановлению пароля.',
            wrongKey: 'Неверный ключ или ключ устарел.',
            passwordChanged: 'Пароль успешно обновлён. Теперь вы можете',
            partnershipIDX: 'Сервис организован в партнерстве с IDX.',
            subject: 'Тема',
            noNotifications: 'Оповещений нет',
            showFull: 'Показать полностью',
            viewFile: 'Посмотреть файл',
            notificationLanguage: 'Язык оповещений',
            receiveNewsletters: 'Получать рассылки',
            fullName: 'Имя',
            exportExcelDisabled: 'Выгрузка Excel недоступна в демонстрационном режиме',
            incidentsUpload: 'Загрузка инцидентов',
            accessLevel: 'Уровень доступа',
            more: 'Подробнее...',
            limitedAccess: 'Доступ ограничен',
            onlySouthern: 'В режиме ограниченного доступа отображаются только инциденты Южного федерального округа.',
            viewOnlyInFullAccess: 'Просмотр данного инцидента доступен в режиме полного доступа.',
            driverPhoto: 'Изображение водителя',
            eventDate: 'Дата события',
            loadingAddress: 'Адрес погрузки',
            unloadingAddress: 'Адрес выгрузки',
            missingPhotoInDB: 'Отсутствует в фотобанке',
            photoInfo: 'Загрузите любое изображение водителя (паспорт, ВУ, фото с камеры) для сравнения с фотобанком ' +
                'лиц, участвовавших в инцидентах с полной или частичной утраты груза.',
            uploadedImage: 'Загруженное изображение',
            foundImage: 'Найденное изображение',
            coincidence: 'Совпадение',
            recovery: 'Восстановить',
            createAccount: 'Создать аккаунт',
            haveNoAccount: 'Все еще нет аккаунта?',
            yourFirstName: 'Ваше имя',
            yourLastName: 'Ваша фамилия',
            services: 'Услуги',
            rating: 'Рейтинг',
            passport: 'Паспорт',
            passportIssued: 'Дата выдачи',
            noData: 'нет данных',
            filterFormGeoAll: 'Все локации',
            allCountries: 'Все страны',
            filterFormPeriodAll: 'Всё время',
            filterFormCargoAll: 'Все категории грузов',
            fieldOfActivity: 'Сфера деятельности',
            truckAndTrailer: 'Автомобиль и полуприцеп',
            drivingLicense: 'Вод. удостоверение',
            forwarder: 'Компания',
            licensePlateTractor: 'Номер тягача',
            autoGos: 'Номер тягача',
            seriesAndNumber: 'Серия и номер',
            licensePlateTrailer: 'Номер прицепа',
            trailerGos: 'Номер прицепа',
            verificationAgree: 'При нажатии на кнопку “Проверить” я подтверждаю, что получил персональные данные ' +
                'физического лица законным способом и имею законное право на передачу этих данных третьим лицам в ' +
                'целях проверки этих данных',
            enterSearchCriteria: 'Введите параметры для поиска',
            recognizePassport: 'Распознать паспорт',
            recognizeDocumentData: 'Распознать данные загруженного документа',
            notValid: 'Недействителен',
            verified: 'Проверен',
            decisionStatus: 'Статус решения',
            status: 'Статус',
            driversLicenseFull: 'Водительское удостоверение',
            period: 'Период',
            licenseOwnerBirthPlace: 'Место рождения владельца ВУ',
            comment: 'Комментарий',
            requestHistory: 'История запросов',
            incidentsNotFound: 'Инциденты отсутствуют',
            incidentsFoundFew: 'Найден один либо два инцидента',
            incidentsFoundMany: 'Найдено 3 и более инцидентов',
            uploaded: 'Загружен',
            check: 'Проверить',
            connectionNamePhone: 'Связка: ФИО - Телефон',
            confirmedF: 'Подтверждена',
            notConfirmedF: 'Не подтверждена',
            insurancePolicy: 'Полис ОСАГО',
            insuranceCompany: 'Страховая компания',
            policySerial: 'Серия полиса',
            policyNumber: 'Номер полиса',
            policyIsRestrict: 'Полис с ограничениями',
            policyNoRestrict: 'Без ограничений',
            policyYesRestrict: 'С ограничениями',
            uniqueNum: 'Уникальный номер полиса',
            bodyNumber: 'Номер кузова',
            regNumber: 'Гос. номер',
            markAuto: 'Марка автомобиля',
            modelAuto: 'Модель автомобиля',
            createDate: 'Дата создания',
            startDate: 'Дата начала',
            endDate: 'Дата окончания',
            participationFaceLong: 'Участие лица в инцидентах, связанных с полной или частичной утратой груза',
            participationDriverLong: 'Участие водителя в инцидентах, связанных с полной или частичной утратой груза',
            number: '№',
            loadingUnloadingAddress: 'Адрес погрузки-выгрузки',
            cargoBrCategory: 'Категория груза',
            info: 'Инфо',
            source: 'Источник',
            participationVehicleLong: 'Участие транспортного средства в инцидентах, связанных с полной или ' +
                'частичной утратой груза',
            trustworthinessScoring: 'Скоринг благонадежности',
            debtsFSSP: 'Задолженности по ФССП в банке данных исполнительных производств',
            companyInfo: 'Информация о компании',
            debtExpired: 'Просрочен',
            error: 'Ошибка',
            debtorInformation: 'Информация о должнике',
            enforcementProceedings: 'Исполнительное производство (номер, дата)',
            detailsOfEP: 'Реквизиты исполнительного производства (ИП)',
            terminationEP: 'Дата и причина окончания или прекращения ИП',
            subjectOfExecution: 'Предмет исполнения',
            amountOfOutstandingDebt: 'Сумма непогашенной задолженности',
            infoBailiriffs: 'Инфо об отделе судебных приставов и приставе',
            go: 'Перейти',
            moreDetails: 'Подробнее',
            analysisText: 'Помогает оценивать риски и выявлять новые тенденции в деятельности преступных группировок',
            incidentsText: 'Картина с хищениями грузов в реальном масштабе времени по всей территории России',
            verificationText: 'Верификация водителя, транспортного средства и участников перевозки для снижения рисков',
            routes: 'Маршруты',
            support: 'Поддержка',
            logisticsProcessAudit: 'Аудит логистических процессов',
            verificationCriteria: 'Внесено {count} из {total} критериев поиска',
            cargoCategories: 'Категории грузов',
            typesOfIncidents: 'Виды инцидентов',
            geographyOfIncidents: 'География инцидентов',
            damageAmount: 'Размер ущерба',
            selectedCategories: 'Выбранные категории',
            russia: 'Россия',
            allTime: 'Всё время',
            allCargoCategories: 'Все категории грузов',
            allDamage: 'Весь ущерб',
            allIncidentCategories: 'Все категории инцидентов',
            found: 'Найдено',
            incidentsFilter: 'Фильтр инцидентов',
            incidentsSearch: 'Поиск инцидентов',
            apply: 'Применить',
            incidentsPeriod: 'Период инцидентов',
            downloadExcel: 'Загрузить в формате Excel',
            filter: 'Фильтр',
            priceRange_0_100K: '0 - 100 ТЫС. ₽',
            priceRange_100K_300K: '100 - 300 ТЫС. ₽',
            priceRange_300K_500K: '300 - 500 ТЫС. ₽',
            priceRange_500K_1M: '500 - 1 МЛН. ₽',
            priceRange_1M_2M: '1 - 2 МЛН. ₽',
            priceRange_2M_3M: '2 - 3 МЛН. ₽',
            priceRange_3M_5M: '3 - 5 МЛН. ₽',
            priceRange_5M_10M: '5 - 10 МЛН. ₽',
            priceRange_10M_25M: '10 - 25 МЛН. ₽',
            priceRangeOver25M: '> 25 МЛН. ₽',
            fromTo: 'С {from} по {to}',
            totalDamage: 'Общий ущерб',
            thousands: 'тыс',
            millions: 'млн',
            billions: 'млрд',
            incidentsByFD: 'Инциденты по ФО',
            top3Incidents: 'Топ 3 инцидента',
            byAttackMethods: 'По способам нападений',
            detailsByCategory: 'Детализация по категории',
            geography: 'География',
            incidentsCount: 'Инцидентов',
            typeOfScene: 'Место происшествия',
            lastIncidents: 'Последние инциденты',
            unidentified: 'Не установлено',
            damageByFederalDistricts: 'Ущерб по федеральным округам',
            federalDistrict: 'федеральный округ',
            characteristicIncident: 'Характерный инцидент',
            top10Cities: 'ТОП 10 НАСЕЛЕННЫХ ПУНКТОВ С НАИБОЛЬШИМ КОЛИЧЕСТВОМ ИНЦИДЕНТОВ',
            top10Regions: 'ТОП 10 РЕГИОНОВ С НАИБОЛЬШИМ КОЛИЧЕСТВОМ ЗАРЕГИСТРИРОВАННЫХ ИНЦИДЕНТОВ',
            lossByMonth: 'Ущерб по месяцам',
            theftInTheParkingLot: 'Кража на стоянке',
            robbery: 'Разбой',
            fraud: 'Мошенничество',
            cargoValue: 'Стоимость груза',
            mainPage: 'Главная',
            damage: 'Ущерб',
            registrationOnTransportExchangeOfAll: 'Регистрация на транспортной бирже всех участников эпизода',
            serviceWillBeAvailableSoon: 'Сервис будет доступен уже скоро',
            routesLongText: 'Сервис ИМПАКТ построения безопасных маршрутов даёт возможность сформировать маршрут с ' +
                'учётом мест безопасной парковки и наличия инцидентов в непосредственной близости от границ маршрута ' +
                'по выбранной категории груза. Каждый маршрут можно дополнить специальными инструкциями для ' +
                'водителей, указать необходимые ограничения, контакты ответственных лиц и телефоны на случай ' +
                'чрезвычайных ситуаций',
            ofSafeParkings: 'Безопасных парковок',
            consultingServices: 'Консультационные услуги',
            servicesLongText: 'Помимо основных онлайн сервисов ИМПАКТ предоставляет своим клиентам целый спектр ' +
                'консалтинговых услуг. Наши эксперты обладают многолетним опытом работы в международных и российских ' +
                'компаниях и располагают самыми передовыми знаниями и практиками в области обеспечения безопасности ' +
                'цепей поставок. Мы проводим аудиты складов, поставщиков, бизнес-процессов, делаем количественную ' +
                'оценку рисков по маршрутам, эффективности затрат на безопасность и оптимальным способам доставки, ' +
                'разрабатываем требования и инструкции по безопасности для грузоотправителей и перевозчиков, ' +
                'проводим сертификацию по различным стандартам, включая TAPA и ISO.',
            transportCompaniesRating: 'Индекс транспортных компаний',
            ratingLongText: 'На основе глубокого анализа решений арбитражных судов относительно участников ' +
                'инцидентов, связанных с полной или частичной утратой груза, данных электронных порталов ' +
                'грузоперевозок, а также ведущих сервисов проверки контрагентов, ИМПАКТ формирует индекс должной ' +
                'осмотрительности и благонадежности транспортных компаний. Информация, которую предоставляет ИМПАКТ ' +
                'включает афилированность и взаимосвязи учредителей, финансовые убытки/потери в результате хищений ' +
                'грузов, соответствие заявляемой деятельности и прочие параметры.',
            settings: 'Настройки',
            profile: 'Профиль',
            notifications: 'Уведомления',
            security: 'Безопасность',
            about: 'О проекте',
            confidentiality: 'Конфиденциальность',
            contacts: 'Контакты',
            helpMessageForFillingOutForm: 'Вспомогательное сообщение о заполнении формы',
            receiveNotificationsByEmail: 'Получать уведомления по Email',
            currentEmail: 'Текущий email',
            newEmail: 'Новый email',
            currentPassword: 'Старый пароль',
            enterCurrentPassword: 'Введите старый пароль',
            enterNewPassword: 'Введите новый пароль',
            repeatNewPassword: 'Повторите новый пароль',
            emailChanged: 'E-mail изменён.',
            currentPasswordWrong: 'Старый пароль неверный.',
            passwordChanged1: 'Пароль изменён.',
            inn: 'ИНН',
            continueMsg: 'Продолжить',
            congratulations: 'Поздравляем',
            accountCreatedConfirm: 'Ваш аккаунт успешно создан и проходит верификацию, на ваш электронный адрес Вы ' +
                'получите ссылку для входа от администратора в ближайшее время.',
            privacyPolicy: 'Политика конфиденциальности',
            alreadyHaveAccount: 'Уже есть аккаунт?',
            cargo: 'Груз',
            inc: 'Инц.',
            logoutMsgConfirm: 'Вы действительно хотите выйти из ИМПАКТ?',
            month: 'мес.',
            year: '{n} лет | {n} год | {n} года | {n} лет',
            total: 'Итого',
            all: 'Все',
            full: 'Полный',
            limited: 'Ограниченный',
            detectAtiCode: 'Определить код ATI.SU',
            atiHint: 'Для определения кода в АТИ необходимо ввести ИНН юридического лица',
            driverLossEpisodesFound: 'Найдены эпизоды утери груза с участием данного водителя.' ,
            driverLossEpisodesNotFound: 'Не найдено эпизодов утери груза с участием данного водителя.',
            expired: 'Истёк срок действия',
            passwordChanged2: 'Ваш пароль успешно изменен. Для входа в учетную запись используйте новый пароль.',
            reports: 'Отчёты',
            reportVerificationsSpak: 'Отчёт по проверкам СПАК',
            get: 'Получить',
            cplSubscription: 'Подписка на КОЛ',
            client: 'Клиент',
            numberOfINN: 'Количество ИНН',
            subscriptionsList: 'Список подписок',
            viewResults: 'Посмотреть результаты',
            commercialPrudenceInLogisticsSubscription: 'Подписка на Коммерческую осмотрительность в логистике',
            newMatch: 'Новое совпадение | Новых совпадений',
            oldMatch: 'Старое совпадение | Старых совпадений',
            notificationSentTo: 'Уведомление отправлено на',
            matches: 'Совпадений',
            new: 'Новых',
            foreignDocuments: 'Иностранные документы',
        },
        titles: {
            analysisByTime: 'Анализ по временным параметрам',
            countByMonth: 'Количество инцидентов по месяцам',
            countByWeekday: 'Распределение по дням недели',
            analysisByRegion: 'Анализ по территориальному признаку',
            analysisByGoods: 'Анализ по категориям товаров',
            analysisByMisc: 'Анализ по видам преступлений, способам и местам совершения краж',
            countByDistricts: 'Количество инцидентов по федеральным округам РФ',
            top15regions: '15 регионов с наибольшим количеством зарегистрированных инцидентов',
            top20cities: 'Топ 20 населенных пунктов с наибольшим количеством инцидентов',
            distributionByCargoCategory: 'Распределение долей по категориям грузов',
            incidentCategoryChart: 'Диаграмма по видам преступлений',
            attachMethodsChart: 'Диаграмма по способам нападений на грузы',
            typeOfSceneChart: 'Диаграмма по месту происшествия',
            incidentsList: 'Список инцидентов',
            addIncident: 'Добавить инцидент',
            byGoodsPrice: 'Распределение по стоимости товаров',
            notification: 'Оповещение',
            notifications: 'Оповещения',
            news: 'Новости',
            services: 'Услуги',
            adminPanel: 'Админ',
            restorePassword: 'Восстановить пароль',
        },
        systemMessages: {
            loading: 'Загрузка...',
        },
        months: {
            1: 'январь', 2: 'февраль', 3: 'март', 4: 'апрель', 5: 'май', 6: 'июнь', 7: 'июль', 8: 'август',
            9: 'сентябрь', 10: 'октябрь', 11: 'ноябрь', 12: 'декабрь',
        },
        days: {
            0: 'понедельник', 1: 'вторник', 2: 'среда', 3: 'четверг', 4: 'пятница', 5: 'суббота', 6: 'воскресенье',
        },
    },
};
