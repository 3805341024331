export const getToken = (): string => {
    const token = window.localStorage.getItem('access_token');
    return token === null ? '' : token;
};

export const getRefreshToken = (): string => {
    const token = window.localStorage.getItem('refresh_token');
    return token === null ? '' : token;
};

export const saveToken = (token: any) => {
    window.localStorage.setItem('access_token', token.access);
    window.localStorage.setItem('refresh_token', token.refresh);
};

export const destroyToken = () => {
    window.localStorage.removeItem('access_token');
    window.localStorage.removeItem('refresh_token');
};

export const getTimeFromToken = (token: string) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map((c: any) => {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload).exp;
};

export const refreshExp = (): number => getTimeFromToken(getRefreshToken()) - Math.round(Date.now() / 1000);

export default { getToken, saveToken, destroyToken, getTimeFromToken, getRefreshToken, refreshExp };
