import moment from 'moment';
import ApiService from '@/common/api.service';
import {
    FETCH_REFERENCES,
} from './actions.type';
import {
  SET_ERROR,
  FETCH_START,
  FETCH_END,
  SET_FILTER_REFS,
  SET_FILTER_VALUES,
  PURGE_FILTER_VALUES,
  PREPARE_FILTER_REQUEST,
  SET_CUSTOM_FILTER, SET_FILTER_FIELD,
} from './mutations.type';
import { i18n } from '@/translations';
import { convertDateToBackend } from '@/common/helpers';


const state = {
    refs: {},
    filterRefs: {},
    filterValues: {searchVal: '',
                   countryVal: [],
                   geoVal: [],
                   date_to: '',
                   date_from: '',
                   cargoVal: [],
                   priceVal: 0,
                   categoryVal: []},
    filterFormData: {searchVal: '',
                     countryVal: [],
                     geoVal: [],
                     date_to: '',
                     date_from: '',
                     cargoVal: [],
                     priceVal: 0,
                     categoryVal: []},
    filterRequest: {},
};

interface State {
    refs: any;
    filterRefs: any;
    filterValues: any;
    filterFormData: any;
    filterRequest: any;
}

const getters = {
    refs(state: State) {
        return state.refs;
    },
    filterRefs(state: State) {
        return state.filterRefs;
    },
    filterValues(state: State) {
        return state.filterValues;
    },
    filterFormData(state: State) {
        return state.filterFormData;
    },
    filterRequest(state: State) {
        return state.filterRequest;
    },
};

const actions = {
    async [FETCH_REFERENCES](context: any, params: any = {}) {
        context.commit(FETCH_START);
        try {
            const resp: any = await ApiService.query('/impact/incident/get_references');
            context.commit(SET_FILTER_REFS, resp.data);
        } catch (e) {
            context.commit(SET_ERROR, e);
        }
        context.commit(FETCH_END);
    },
};

const mutations = {
    [SET_FILTER_REFS](state: State, data: any) {
      state.filterRefs = formatFilterRefs(data);
      state.refs = data;
    },
    [SET_FILTER_VALUES](state: State, data: any) {
      Object.keys(state.filterFormData).map( (filterKey: any) => {
        if (state.filterFormData[filterKey] === -1) {
          state.filterFormData[filterKey] = 0;
        }
        if (state.filterFormData.categoryVal.length === 4) {
          state.filterFormData.categoryVal = [];
        }
      });
      state.filterValues = {...state.filterFormData};
    },
    [SET_CUSTOM_FILTER](state: State, data: any) {
      const temporaryFilterParams: any = {...state.filterFormData, ...data};
      state.filterRequest = {...prepareRequestParams(temporaryFilterParams)};
    },
    [PREPARE_FILTER_REQUEST](state: State, data: any) {
      state.filterRequest = {...prepareRequestParams(state.filterValues)};
    },
    [SET_FILTER_FIELD](state: State, data: { [field: string]: any}) {
      state.filterFormData = { ...state.filterFormData, ...data };
    },
    [PURGE_FILTER_VALUES](state: State, data: any) {
      const keyToRemove: any = data.filterName;
      const filterValue: any = data.filterValue;
      const filterDefaults = {searchVal: '',
                              countryVal: [],
                              geoVal: [],
                              date_to: '',
                              date_from: '',
                              cargoVal: [],
                              priceVal: 0,
                              categoryVal: []};
      if (keyToRemove && state.filterValues.hasOwnProperty(keyToRemove)) {
        const mKeys = ['countryVal', 'geoVal', 'cargoVal', 'categoryVal'];
        if (filterValue && mKeys.includes(keyToRemove)) {
          const valIndex = state.filterValues[keyToRemove].indexOf(filterValue);
          if (valIndex > -1) {
            state.filterValues[keyToRemove].splice(valIndex, 1);
          }
        } else {
          const value = (keyToRemove === 'searchVal') ? '' : 0;
          state.filterValues[keyToRemove] = value;
        }

        state.filterValues = {...state.filterValues};
      } else {
        state.filterValues = {...filterDefaults};
      }
      state.filterFormData = {...state.filterValues};
    },
};

const prepareRequestParams = (data: any) => {
  const requestParams: any = {};
  const priceRanges: any = {1: [0, 100000],
                            2: [100000, 500000],
                            3: [500000, 1000000],
                            4: [1000000, 3000000],
                            5: [3000000, 5000000],
                            6: [5000000, -1]};
  const currentDateFormatted: any = moment().format('YYYY-MM-DD');
  const periodRanges: any = {1: [currentDateFormatted,
                                 moment().subtract(1, 'months').format('YYYY-MM-DD')],
                             2: [currentDateFormatted,
                                 moment().subtract(3, 'months').format('YYYY-MM-DD')],
                             3: [currentDateFormatted,
                                 moment().subtract(6, 'months').format('YYYY-MM-DD')],
                             4: [currentDateFormatted,
                                 moment().subtract(12, 'months').format('YYYY-MM-DD')],
                             5: [currentDateFormatted,
                                 moment().subtract(24, 'months').format('YYYY-MM-DD')],
                             6: [currentDateFormatted,
                                 moment().subtract(36, 'months').format('YYYY-MM-DD')],
                             7: [currentDateFormatted,
                                 moment().subtract(60, 'months').format('YYYY-MM-DD')]};
  if (data.searchVal) {
    requestParams.s = data.searchVal;
  }
  if (data.priceVal) {
    if (priceRanges[data.priceVal][0] > -1) {
      requestParams.price_from = priceRanges[data.priceVal][0];
    }
    if (priceRanges[data.priceVal][1] > -1) {
      requestParams.price_to = priceRanges[data.priceVal][1];
    }
  }
  if (data.date_to) {
    requestParams.date_to = convertDateToBackend(data.date_to);
  }
  if (data.date_from) {
    requestParams.date_from = convertDateToBackend(data.date_from);
  }
  if (data.countryVal.length > 0) {
    if (data.countryVal.includes(-1)) {
      requestParams.country = [];
    } else {
      requestParams.country = data.countryVal;
    }
  }
  if (data.cargoVal.length > 0) {
    if (data.cargoVal.includes(-1)) {
      requestParams.cargo_categories = [];
    } else {
      requestParams.cargo_categories = data.cargoVal;
    }
  }
  if (data.categoryVal.length > 0) {
    if (data.categoryVal.includes(-1)) {
      requestParams.incident_categories = [];
    } else {
      requestParams.incident_categories = data.categoryVal;
    }
  }
  if (data.geoVal.length > 0) {
    if (data.geoVal.includes(-1)) {
      requestParams.federal_districts = [];
    } else {
      requestParams.federal_districts = data.geoVal;
    }
  }
  return requestParams;
};

const formatFilterRefs = (data: any) => {
  const references: any = {};
  Object.keys(data).map( (field) => {
      references[field] = [];
      const suffix: string = (field === 'federal_district') ? ' ФО' : '';
      const addition: number = (field === 'price_range') ? 1 : 0;
      Object.keys(data[field]).map( (item: any) => {
          let idValue: number | string;
          if ('id' in data[field][item]) {
              idValue = data[field][item].id;
          } else {
              idValue = parseInt(item, 10) + addition;
          }
          references[field].push({value: idValue,
                                  name_ru: data[field][item].name_ru + suffix,
                                  name_en: data[field][item].name_en});
      });
      references[field].sort( (a: any, b: any) => {
          return a.value - b.value;
      } );
  });
  references.period_ranges = [{value: 1, name_ru: '1 месяц', name_en: '1 month'},
                              {value: 2, name_ru: '3 месяца', name_en: '3 months'},
                              {value: 3, name_ru: '6 месяцев', name_en: '6 months'},
                              {value: 4, name_ru: '1 год', name_en: '1 year'},
                              {value: 5, name_ru: '2 года', name_en: '2 years'},
                              {value: 6, name_ru: '3 года', name_en: '3 years'},
                              {value: 7, name_ru: '5 лет', name_en: '5 years'}];
  references.incident_category.push({value: 0, name_ru: 'не установлено',
                                     name_en: 'unidentified'});
  return references;
};

export default {
    state,
    actions,
    mutations,
    getters,
};
