import ApiService from '@/common/api.service';
import JwtService from '@/common/jwt.service';
import {
    EXPORT_INCIDENTS,
    FETCH_INCIDENT,
    FETCH_INCIDENTS,
    FETCH_INCIDENTS_SUMMARY,
} from './actions.type';
import {
    SET_INCIDENTS,
    SET_ERROR,
    FETCH_START,
    SET_INCIDENT,
    PURGE_INCIDENT,
    FETCH_END,
    EXPORT_START,
    EXPORT_END,
    SET_INCIDENTS_SUMMARY, SET_FILTER_PARAMS, PREPARE_FILTER_REQUEST,
} from './mutations.type';
import saveAs from 'file-saver';
import {convertDateToBackend} from '@/common/helpers';


const state = {
    errors: null,
    incidents: [],
    incidentsCount: 0,
    nextPage: null,
    previousPage: null,
    incident: null,
    summary: {
        total: 0,
        on_map: 0,
        damage: 0,
        damage_avg: 0,
        max_date: '',
        min_date: '',
        damage_change: null,
        damage_increased: false,
    },
};

interface State {
    incidents: object[];
    incidentsCount: number;
    nextPage: string;
    previousPage: string;
    incident: object | null;
    summary: object;
}

const getters = {
    incidentsCount(state: State) {
        return state.incidentsCount;
    },
    incidents(state: State) {
        return state.incidents;
    },
    incident(state: State) {
        return state.incident;
    },
    summary(state: State) {
        return state.summary;
    },
};

const actions = {
    async [FETCH_INCIDENTS](context: any, params: any = {}) {
        context.commit(FETCH_START);
        try {
            const resp: any = await ApiService.query('/impact/incident', params);
            context.commit(SET_INCIDENTS, resp.data);
        } catch (e) {
            context.commit(SET_ERROR, e);
        }
        context.commit(FETCH_END);
    },
    async [FETCH_INCIDENTS_SUMMARY](context: any, params: any) {
        context.commit(FETCH_START);
        try {
            const resp: any = await ApiService.query('/impact/incident/get_summary', params);
            context.commit(SET_INCIDENTS_SUMMARY, resp.data.summary);
            context.commit(SET_FILTER_PARAMS, resp.data);
        } catch (e) {
            context.commit(SET_ERROR, e);
        }
        context.commit(FETCH_END);
    },
    async [FETCH_INCIDENT](context: any, slug: string) {
        context.commit(FETCH_START);
        context.commit(PURGE_INCIDENT);
        try {
            const resp: any = await ApiService.get('/impact/incident', slug);
            context.commit(SET_INCIDENT, resp.data);
        } catch (e) {
            context.commit(SET_ERROR, e);
        }
        context.commit(FETCH_END);
    },
    async [EXPORT_INCIDENTS](context: any, lang: string) {
        context.commit(EXPORT_START);
        try {
            context.commit(PREPARE_FILTER_REQUEST);
            const preparedData = {
                ...context.rootState.filter.filterRequest,
                lang: lang ? lang : 'ru',
            };
            const resp: any = await ApiService.getFile('/impact/incident/export_excel',  preparedData);
            const contentDisposition = resp.headers['content-disposition'];
            let fileName = 'incidents.pdf';
            if (contentDisposition) {
                const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
                if (fileNameMatch.length === 2) {
                    fileName = fileNameMatch[1];
                }
            }
            saveAs(resp.data, fileName);
        } catch (e) {
            context.commit(SET_ERROR, e);
        }
        context.commit(EXPORT_END);
    },

};

const mutations = {
    [SET_INCIDENTS](state: State, data: any) {
        state.incidents = data.results;
        state.incidentsCount = data.count;
        state.nextPage = data.next;
        state.previousPage = data.previous;
    },
    [SET_INCIDENT](state: State, data: any) {
        state.incident = data;
    },
    [PURGE_INCIDENT](state: State) {
        state.incident = null;
    },
    [SET_INCIDENTS_SUMMARY](state: State, data: any) {
        state.summary = data;
    },
};

export default {
    state,
    actions,
    mutations,
    getters,
};
