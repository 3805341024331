
import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { setLocale } from '@/common/locale.service';
import { i18n } from '@/translations';

@Component({
  computed: mapGetters(['currentUser']),
})
export default class BasicHeader extends Vue {

  private currentUser!: any;
  private showLangDropdown: boolean = false;
  private menuButtonActive: boolean = false;

  private pathNames = {
    analysis: i18n.t('strings.analysis'),
    verification: i18n.t('strings.verification'),
    prudence: i18n.t('strings.commercialPrudenceInLogistics'),
    incidents: i18n.t('strings.incidents'),
    services: i18n.t('strings.services'),
    rating: i18n.t('strings.rating'),
    faq: i18n.t('strings.support'),
    settings: i18n.t('strings.settings'),
    policy: i18n.t('strings.privacyPolicy'),
    administration: i18n.t('titles.adminPanel'),
  };

  private subNames = {
    analysis: {
      analysisIncidents: i18n.t('strings.typesOfIncidents'),
      analysisGeo: i18n.t('strings.geographyOfIncidents'),
      analysisDamage: i18n.t('strings.damageAmount'),
      analysisCargo: i18n.t('strings.cargoCategories'),
    },
    settings: {
      settingsProfile: i18n.t('strings.profile'),
      settingsNotify: i18n.t('strings.notifications'),
      settingsSecurityEmail: i18n.t('strings.security'),
    },
    administration: {
      administrationUsers: i18n.t('strings.users'),
      administrationAPIUsers: 'Клиенты API',
      administrationNotifications: i18n.t('strings.notifications'),
      administrationVerifications: i18n.t('strings.verifications'),
      administrationUploadIncidents: i18n.t('strings.incidentsUpload'),
      administrationUserSettings: i18n.t('strings.userSettings'),
      administrationAPIUserSettings: 'Клиент API',
      administrationReport: i18n.t('strings.reports'),
    },
  };

  private closeLangDropdown() {
    if (this.showLangDropdown) {
      this.showLangDropdown = false;
    }
  }

  private changeLocale(locale: string) {
    setLocale(locale);
    this.showLangDropdown = false;
    this.$i18n.locale = locale;
  }

  private switchMobileMenu() {
    this.menuButtonActive = !this.menuButtonActive;
    this.$emit('switchMenu');
  }
}
